<template>
  <div class="_Group">
      
    <div class="_banner_kv ">
      <div v-if="bannerData.length" class="_banner_kv__bg">
        <div  class="_banner_kv__bgZoom" >
          <div v-for="(item, index ) in bannerData" :key="index"  class="_banner_kv__img _home_banner__img">
              <img v-if="item.photo && index < 1" class="_banner_kv__bgImg" :src="item.photo">
              <div v-if="item.video && index < 1" class="_banner_kv__iframe">
                <youtube :video-id="item.video.video_id" ref="youtube" :player-vars="{autoplay: 0,controls:0,loop:1,playlist:item.video.video_id,rel:0,volumn:0}"  @ready="videoReady"></youtube>
              </div>
            </div>
        </div>
      </div>
      
      <div class="_banner_kv__container _container">
        <div v-if="bannerData.length" class="_banner_kv__info  _banner_kv__info--center" :class="[ $store.state.loading ? '' : 'active']">
          <strong class="_banner_kv__name" :class="[ $store.state.loading ? '' : 'active']">{{ bannerData[0].category.name }}</strong>
          <h1 class="_banner_kv__headLine _banner_kv__headLine--middle">{{bannerData[0].name}}</h1>
        </div>
        <button v-scroll-to="'#main'" class="_banner_kv__scrollDown _btn_hollow _btn_hollow--red _font_btn _btn_hollow--radius font-weight-bold">Scroll Down</button>
      </div>
    </div>

    <div  id="main" class="_main _group_info">
      <div class="_group_bgn">
        <div class="_group_bgn__gray">      
          <svg viewBox="0 0 1487 1557" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-193.000000, -2125.000000)" fill="#F6F6F6">
                      <path d="M952.478368,2227.41694 L1679.80379,3681.5 L1679.80379,3681.5 L123.196212,3681.5 L850.521632,2227.41694 C864.604416,2199.26237 898.844562,2187.85492 926.999137,2201.93771 C938.02389,2207.45224 946.963839,2216.39219 952.478368,2227.41694 Z" id="Triangle" transform="translate(901.500000, 2903.500000) rotate(270.000000) translate(-901.500000, -2903.500000) "></path>
                  </g>
              </g>
          </svg>
        </div>
      </div>
      <!-- <div class="_container">
        <div class="_group_info__box _info_box">
          <h2 class=" _info_box__title _info_box__title--red" >ADK TAIWAN is an advertising agency full of creative energy.</h2>
          <p class="_info_box__des" v-html="$t('about_intro')"></p> 
        </div>
      </div> -->

      <div class="_container _container--large">
        <div class="_group_info__photo">
          <div class="_group_info__card _group_info__card--1 _card _animation_scroll__stop--3d">
            <div class="_card_cover _card_cover--gray">
              <img class="_group_info__cardImg _card_cover__img" v-lazy="'/img/cover-1.jpg?v=1'">
            </div>
          </div>
          <div class="_group_info__card _group_info__card--2 _card _animation_scroll__stop--3d">
            <div class="_card_cover _card_cover--gray">
              <img class="_group_info__cardImg _card_cover__img" v-lazy="'/img/cover-4.png?v=1'">
            </div>
          </div>
        </div>
      </div>

      <div class="_container _container">
        <!-- <h2 class="_group_info__strong  _animation_scroll__stop--3d">Activate Consumers, <br/>Amplify Value</h2> -->
        <ul class="_group_info__list">
          <li class="_group_info__listItem">
            <h4 class="_group_info__listTitle" v-html="$t('about_title_1')">TECHNOLOGY HUMANITY</h4>
            <p class="_group_info__listDes _info_box__des" v-html="$t('about_des_1')">我們相信，再創新的科技，都無法自己發聲，所以我們努力運用創造力，讓每個科技能發出驅動世界聲音。</p>
          </li>
          <li class="_group_info__listItem">
            <h4 class="_group_info__listTitle" v-html="$t('about_title_2')">CONNECTED CONTENTED</h4>
            <p class="_group_info__listDes _info_box__des" v-html="$t('about_des_2')">我們想做的，不只是單純產生連結，而是要以打動人心的廣告內容為目標讓人與人之間建立關係，推動人們向前邁進</p>
          </li>
          <li class="_group_info__listItem">
            <h4 class="_group_info__listTitle" v-html="$t('about_title_3')">ACCELERATOR CULTURE DRIVER</h4>
            <p class="_group_info__listDes _info_box__des" v-html="$t('about_des_3')">我們始終相信創造文化並使其壯大，才是真正的成長即使必須耗費更多的時間與力氣去完成，但這是唯一能推動並改變世界的作法。</p>
          </li>
        </ul>
      </div>
      

      <div class="_container _container--large d-flex justify-content-end align-items-end">
        <div class="_group_info__card _group_info__card--3  _card_cover--gray _animation_scroll__stop--3d">
          <img class="_group_info__cardImg _card_cover__img" v-lazy="'/img/cover-3.png?v=1'">
        </div>
        <div class="_group_info__card _group_info__card--4  _card_cover--gray _animation_scroll__stop--3d">
          <img class="_group_info__cardImg _card_cover__img" v-lazy="'/img/cover-2.png?v=1'">
        </div>
      </div>
      <div class="_container _container--large">
          <div class="_group_info__end">
            <h4 class="_group_info__listTitle" v-html="$t('about_des_end_title')">Our Business Domain</h4>
            <p class="_group_info__listDes _info_box__des" v-html="$t('about_des_end')"></p>
          </div>
      </div>

      <!-- <div class="_container">
        
        <div class="_group_info__box--3  _animation_scroll__stop--3d">
          
          <div class="_group_info__spec _animation_scroll__stop--3d _group_info__box _info_box">
            <h2 class=" _info_box__title _info_box__title--red" v-html="$t('about_title_1')">TECHNOLOGY HUMANITY</h2>
            <p class="_info_box__des" v-html="$t('about_des_1')">Consumer Activation" is an advanced form of comprehensive communications that motivates consumers to take action. It encompasses a consistent focus on the actual daily lives of consumers and the creation of an array of touchpoints that encourage consumers to engage with brands and to enjoy highly positive brand experiences.</p>
            <br/><br/>
            <h2 class=" _info_box__title _info_box__title--red" v-html="$t('about_title_2')">TECHNOLOGY HUMANITY</h2>
            <p class="_info_box__des" v-html="$t('about_des_2')">Consumer Activation" is an advanced form of comprehensive communications that motivates consumers to take action. It encompasses a consistent focus on the actual daily lives of consumers and the creation of an array of touchpoints that encourage consumers to engage with brands and to enjoy highly positive brand experiences.</p> 
            <br/><br/>
            <h2 class=" _info_box__title _info_box__title--red" v-html="$t('about_title_3')">TECHNOLOGY HUMANITY</h2>
            <p class="_info_box__des" v-html="$t('about_des_3')">Consumer Activation" is an advanced form of comprehensive communications that motivates consumers to take action. It encompasses a consistent focus on the actual daily lives of consumers and the creation of an array of touchpoints that encourage consumers to engage with brands and to enjoy highly positive brand experiences.</p> 
          </div>
          <h3 class="_group_info__footLine  _animation_scroll__stop--3d">ADK is a group of professional units that move consumers.</h3>
        </div>
      </div> -->
    </div>

    <div class="_group_team">
      <div v-if="team.length" class="_group_team__list">

        <div v-for="(item, index ) in team" :key="index"  class="_group_team__item">
          <router-link :to="'/about/'+item.slug" class="_group_team__link">
            <div class="_group_team__cover" style="">
              <img :src="item.photo">
            </div>
            <div class="_group_team__info">
              <strong class="_group_team__title">{{ item.job_title }}</strong>
              <strong class="_group_team__name">{{ item.name }}</strong>
              <!-- <strong v-html="item.description" class="_group_team__des"></strong> -->
            </div>
          </router-link>
          
        </div>

      </div>
    </div>
    <transition name="_transition-group" duration="800">
      <router-view :team="team" />
    </transition>
    
    
  </div>
</template>

<script>
import commonMixin from '../utils/commonMixin'
import $ from 'jquery'

export default {
  name: 'Group',

  mixins:[commonMixin],
  data:function(){ 
      return{
          bannerData:[],
          imageData:[],
          team:""
      }
  },
  // beforeRouteEnter (to, from, next) {
  //   console.log('Group beforeRouterEnter.');
    
  //   // Vue.nextTick(function(){
  //   //   //console.log(this.getAPIdatas);
  //   // }
  //   //console.log("beforeRouteEnter:", next)
  //   next(false,vm => {
  //     console.log(vm);
  //   })
  //   next(false);
    
  // },
  // beforeResolve(){
  //   console.log('Group beforeResolve.');
  // },
  // beforeCreate(){
  //   console.log('Group beforeCreate.');
  // },
  // beforeRouteUpdate (to, from, next) {
  //   console.log('Group beforeRouteUpdate.');
  //     next();
  // },
  mounted:function(){
    var _this=this;
    this.getAPIdatas();
    console.log('vuemeta::::',this.vuemeta);
    //this.initScrollMagic();
    //console.log(this.$route.name)
    // if(!this.$store.state.bannerData){
    //   this.loadBanner('group',false)
    // }
    
  },
  methods:{
    getAPIdatas() {
      var _this=this
      this.axios({
        method: "get",
        url: "/api/banner?category_slug=about"
      })
      .then(response => {
        for(let i in response.data.items){
            this.imageData.push(response.data.items[i].photo)
          }
          this.bannerData=response.data.items
          //console.log('bannerData',this.bannerData)
          //_this.$store.commit('setBannerData',data)
          this.preloadImages(this.imageData,function(){
            _this.$store.commit('setLoading',false);
            _this.tweenText($('._banner_kv__headLine'),true)
            _this.initBanner();
            //console.log("allImageLoaded!!")
          })
      })
      .catch(function(err) {
        console.log(err);
      });
      
      this.axios({
        method: "get",
        url: "/api/member",
      })
        .then(response => {
          
          //next();
          this.team = response.data.items;
          console.log("member:",this.team);
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    memberFilter:function(){

    },
    initScrollMagic:function(){
      //var _this=this;
        this.photoSence1 = this.$scrollmagic.scene({
          triggerElement:'._group_info__photo',
          duration:'200%',
          triggerHook: 1
          }).setTween("._group_info__card--1", {y: 300})
        this.$scrollmagic.addScene(this.photoSence1);

        this.photoSence1_1 = this.$scrollmagic.scene({
          triggerElement:'._group_info__photo',
          duration:'200%',
          triggerHook: 1
          })
          .setTween($("._group_info__card--1").find('._card_cover__img'), {css:{'transform':'scale(1,1)'}})
        this.$scrollmagic.addScene(this.photoSence1_1)

        this.photoSence2 = this.$scrollmagic.scene({
          triggerElement:'._group_info__photo',
          duration:'200%',
          triggerHook: 1
          }).setTween("._group_info__card--2", {y: 500})
        this.$scrollmagic.addScene(this.photoSence2);

        this.photoSence2_1 = this.$scrollmagic.scene({
          triggerElement:'._group_info__photo',
          duration:'200%',
          triggerHook: 1
          }).setTween($("._group_info__card--2").find('._card_cover__img'),{css:{'transform':'scale(1,1)'}})
        this.$scrollmagic.addScene(this.photoSence2_1);

        this.strongSence = this.$scrollmagic.scene({
          triggerElement:'._group_info__strong',
          duration:'200%',
          triggerHook: 1
          }).setTween("._group_info__strong", {y: 0});
        this.$scrollmagic.addScene(this.strongSence);

        // this.photoSence3 = this.$scrollmagic.scene({
        //   triggerElement:'._group_info__box--3',
        //   duration:'200%',
        //   triggerHook: 1
        //   }).setTween("._group_info__card--3", {y: 150});
        // this.$scrollmagic.addScene(this.photoSence3);

        // this.photoSence3 = this.$scrollmagic.scene({
        //   triggerElement:'._group_info__box--3',
        //   duration:'200%',
        //   triggerHook: 1
        //   }).setTween($("._group_info__box--3").find('._card_cover__img'),{css:{'transform':'scale(1,1)'}})
        // this.$scrollmagic.addScene(this.photoSence3);
        
        // this.photoSence4 = this.$scrollmagic.scene({
        //   triggerElement:'._group_info__box--3',
        //   duration:'200%',
        //   triggerHook: 1
        //   }).setTween("._group_info__spec", {y: 250});
        // this.$scrollmagic.addScene(this.photoSence4);
        //console.log($,_this);

        this.photoSence5 = this.$scrollmagic.scene({
          triggerElement:'._group_info__footLine',
          duration:'200%',
          triggerHook: 1
          }).setTween("._group_info__footLine", {y: 100});
        this.$scrollmagic.addScene(this.photoSence5);
       // console.log($,_this);

       this.photoSence6 = this.$scrollmagic.scene({
          triggerElement:'._group_info',
          duration:'200%',
          triggerHook: 1
          }).setTween("._group_info__bgn", {css:{'background-position-y':'-300'}});
        this.$scrollmagic.addScene(this.photoSence6);

       

      }
      
      //let worksItem = getElementsByClassName("test");
      
  },
  computed: {
    
  },
  destroyed () {
    this.destoryBanner()
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setLoading',true)
    next()
  }
}
</script>
