<template>
  <div class="_news">
    <div class="_banner_kv ">
        <div class="_banner_kv__bg">
          <div class="_banner_kv__bgZoom" >
              <div v-for="(item, index ) in bannerData" :key="index"  class="_banner_kv__img _home_banner__img">
                <img v-if="item.photo && index < 1" class="_banner_kv__bgImg" :src="item.photo">
                <div v-if="item.video && index < 1" class="_banner_kv__iframe">
                  <youtube :video-id="item.video.video_id" ref="youtube" :player-vars="{autoplay: 0,controls:0,loop:1,playlist:item.video.video_id,rel:0,volumn:0}"  @ready="videoReady"></youtube>
                </div>
            </div>
          </div>
        </div>

        <div class="_banner_kv__container _container">
            <div v-if="bannerData.length" class="_banner_kv__info _banner_kv__info--center" :class="[ $store.state.loading ? '' : 'active']">
                <strong class="_banner_kv__name" :class="[ $store.state.loading ? '' : 'active']">{{ bannerData[0].category.name }}</strong>
                <h1   class="_banner_kv__headLine _banner_kv__headLine--middle">{{bannerData[0].name}}</h1>
            </div>
            <button v-scroll-to="'#main'" class="_banner_kv__scrollDown _btn_hollow _btn_hollow--red _font_btn _btn_hollow--radius font-weight-bold">Scroll Down</button>
        </div>
    </div>
    
    <div id="main" class="_main _news_list">

      <div class="_news_bgn">
        <div class="_news_bgn__gray _news_bgn__gray--1">
          <svg viewBox="0 0 1541 751" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(0.000000, -992.000000)" fill="#F6F6F6">
                      <polygon id="Rectangle-Copy-16" transform="translate(770.500000, 1367.500000) scale(-1, -1) translate(-770.500000, -1367.500000) " points="1541 992 1541 1743 0 1743"></polygon>
                  </g>
              </g>
          </svg>
        </div>
        <div class="_news_bgn__gray _news_bgn__gray--2">
          <svg viewBox="0 0 1542 1613" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-138.000000, -2479.000000)" fill="#F6F6F6">
                      <path d="M925.479615,2581.4319 L1680.71614,4091.5 L1680.71614,4091.5 L68.2838563,4091.5 L823.520385,2581.4319 C837.601792,2553.27663 871.841379,2541.86752 899.996643,2555.94892 C911.023372,2561.46377 919.964772,2570.40517 925.479615,2581.4319 Z" id="Triangle" transform="translate(874.500000, 3285.500000) rotate(270.000000) translate(-874.500000, -3285.500000) "></path>
                  </g>
              </g>
          </svg>
        </div>
      </div>

        <div class="_container">

            <div v-for="(list , index) in newsList" :key="index" class="_news_list__section">
                <strong class="_news_list__year">{{list.publish_year}}</strong>
                <div>
                    <div v-for="(item,index) in list.data" :key="index" class="_news_list__row">
                      <router-link :to="'/news/'+item.slug" class="_group_team__link">
                        <div class="_news_list__card _card">
                            <div class="_news_list__cardCover ">
                                <img class="_card_cover__img" v-lazy="item.photo">
                            </div>
                            <em class="_news_list__mark _card_mark _card_mark--red">{{monthNames[getPublishMonth(item.publish_date)]}} {{getPublishDay(item.publish_date)}}</em>
                            <h3 class="_news_list__title _card_title">{{item.name}}</h3>
                            <p v-html="item.list_description" class="_card_des"></p>
                            <p class="_news_read">Read More</p>
                        </div>
                        
                      </router-link>
                      
                    </div>
                </div>
            </div>

            
        </div>
    </div>

    <transition name="_transition-group" duration="800">
      <router-view :news="news"/>
    </transition>

  </div>
</template>

<script>
import commonMixin from '../utils/commonMixin'
import $ from 'jquery'
export default {
  name: 'News',
  data:function(){
      return{
        bannerData:[],
        imageData:[],
        news:[],
        newsList:[],
        monthNames:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
        detail:{}
      }
  },
  mounted:function(){
    this.getAPIdatas();
    this.initScrollMagic();
  },
  methods:{
    getAPIdatas() {
      var _this=this
      this.axios({
        method: "get",
        url: "/api/banner?category_slug=news"
      })
      .then(response => {
        for(let i in response.data.items){
            this.imageData.push(response.data.items[i].photo)
          }
          this.bannerData=response.data.items
          //console.log('bannerData',this.bannerData)
          //_this.$store.commit('setBannerData',data)
          this.preloadImages(this.imageData,function(){
            _this.$store.commit('setLoading',false);
            _this.tweenText($('._banner_kv__headLine'),true)
            _this.initBanner();
            //console.log("allImageLoaded!!")
          })
      })
      .catch(function(err) {
        console.log(err);
      });

      this.axios({
        method: "get",
        url: "/api/news",
      })
        .then(response => {
          //console.log(response);
          //next();
          this.news = response.data.items;
          //console.log("news::::",this.news )
          this.reMakeNews();
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    reMakeNews:function(){
      for(var n in this.news){
        var myDate=this.news[n].publish_date
        this.news[n].publish_year=this.getPublishYear(this.news[n].publish_date)
      }
      

      var  map = {}
      this.newsList=[]
      for(let i in this.news){
          var  item = this.news[i];
          //console.log(this.getPublishDay(item.publish_date))
          if (!map[item.publish_year]){
              this.newsList.push({
                  publish_year:item.publish_year,
                  data: [item]
              });
              map[item.publish_year] = item;
          } else {
              for ( let  j in this.newsList){
                  var  listItem = this.newsList[j];
                  if (listItem.publish_year == item.publish_year){
                      listItem.data.push(item);
                      break ;
                  }
              }
          }
      } 
      //console.log('unsort',this.newsList);
      this.newsList.sort(this.sortArr);
      //console.log('sort',this.newsList);
    },
    sortArr:function(a,b){
        return b.publish_date-a.publish_date
    },
    initScrollMagic:function(){
      var _this=this;
        _this.bgSenceGray_1 = this.$scrollmagic.scene({
          triggerElement:'._news_bgn',
          duration:'300%',
          triggerHook: 1
        })
        .setTween("._news_bgn__gray--1", {y: '-500px'})
        _this.$scrollmagic.addScene(this.bgSenceGray_1)

        _this.bgSenceGray_2 = this.$scrollmagic.scene({
          triggerElement:'._news_bgn',
          duration:'200%',
          triggerHook: 1
        })
        .setTween("._news_bgn__gray--2", {y: "-500px"})
        _this.$scrollmagic.addScene(this.bgSenceGray_2);
        
      }
      
  },
  gotoDetail:function(arg){
    this.detail=arg;
  },
  destroyed () {
    this.destoryBanner()
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setLoading',true)
    next()
  }
}
</script>
