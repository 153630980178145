
<template>
  <div class="_sidePop _sidePop--large">
      <div v-if="filterData" class="_sidePop__box _transition_slideLift">
        <div class="_sidePop__boxSide"></div>
        <div  class="_sidePop_content">
          <div class="_sidePop__bgn ">
            <!-- <img :src="filterData.photo" /> -->
          </div>
          
          
          <!-- <h3 class="_sidePop__name">{{ filterData.name }}</h3> -->
          <strong class="_sidePop__em">{{filterData.publish_year}} / {{getPublishMonth(filterData.publish_date)+1}} / {{getPublishDay(filterData.publish_date)}}</strong>
          <h2 class="_sidePop__title">{{filterData.name}}</h2>
         
          <img class="_sidePop__photo " :src="filterData.photo"/>
          
          
          
          <div class="_sidePop__des" v-html="filterData.description"></div> 
        </div>
        <router-link class="_sidePop__close" to="/news">
            <div class="_header_trigger__icon">
              <span class="_header_trigger__iconLine _header_trigger__iconLine--close _sidePop__closeLine"></span>
            </div>
        </router-link>  
      </div>
  </div>
</template>


<script>
import $ from 'jquery'
export default {
  name: 'NewsDetail',
  props: {
    news:{},
  },
  data:function(){
      return{
        detail:Object,
        monthNames:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      }
  },
  mounted:function(){
    var _this=this;
    //this.getAPIdatas();
    //this.initBanner();
    //console.log('team',this.team);
    setTimeout(function(){
      $('._sidePop').addClass('active')
      console.log(_this.news)
    },300)
    
  },
  computed: {
    filterData: function () {
      
      var _this=this
      //console.log('this.news::::',_this.news.find)
      if(_this.news.find){
        return _this.news.find(function(item, index, array){
          return item.slug == _this.$route.params.slug
        })
      }
      return false  
    } 
  },
  destroyed () {
    this.destoryBanner()
  },
  beforeRouteLeave(to, from, next) {
    $('._sidePop').removeClass('active')
    next()
  }
  

}
</script>
