<template>
  <div class="_experience">
    <div class="_banner_kv _experience_banner">
        <div v-if="bannerData.length" class="_banner_kv__bg">
          <div class="_banner_kv__bgZoom" >
              <div v-for="(item, index ) in bannerData" :key="index"  class="_banner_kv__img _home_banner__img">
                <img v-if="item.photo && index < 1" class="_banner_kv__bgImg" :src="item.photo">
                <div v-if="item.video && index < 1" class="_banner_kv__iframe">
                  <youtube :video-id="item.video.video_id" ref="youtube" :player-vars="{autoplay: 0,controls:0,loop:1,playlist:item.video.video_id,rel:0,volumn:0}"  @ready="videoReady"></youtube>
                </div>
              </div>
          </div>
        </div>

        <div class="_banner_kv__container _container">
          <div v-if="bannerData.length" class="_banner_kv__info  _banner_kv__info--center" :class="[ $store.state.loading ? '' : 'active']">
            <strong class="_banner_kv__name" :class="[ $store.state.loading ? '' : 'active']">{{ bannerData[0].category.name }}</strong>
            <h1 v-if="bannerData.length>0" class="_banner_kv__headLine _banner_kv__headLine--middle">{{bannerData[0].name}}</h1>
          </div>
          <button v-scroll-to="'#main'" class="_banner_kv__scrollDown _btn_hollow _btn_hollow--red _font_btn _btn_hollow--radius font-weight-bold">Scroll Down</button>
        </div>
    </div>

    <div id="main" class="_main _experience_info">
      <div class="_experience_bgn">
        <div class="_experience_bgn__red">
          <svg viewBox="0 0 613 299" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(0.000000, -992.000000)" fill="#CA0B21">
                      <polygon id="Rectangle-Copy-16" transform="translate(306.500000, 1141.500000) scale(-1, -1) translate(-306.500000, -1141.500000) " points="613 992 613 1291 0 1291"></polygon>
                  </g>
              </g>
          </svg>
        </div>
        
      </div>
      <div class="_container">
        <div class="_experience_info__con">
          <div class="_experience_info__box _info_box">
            <h2 class=" _info_box__title _info_box__title--small _info_box__title-- _info_box__title--red" v-html="$t('capabliltieses_title_1')">WE ARE A CONSUMER ACTIVATION COMPANY</h2>
            <p class="_info_box__des" v-html="$t('capabliltieses_des_1')">Consumer Activation is an advanced form of comprehensive communications that motivates consumers to take action. It encompasses a consistent focus on the actual daily lives of consumers and the creation of an array of touchpoints that encourage consumers to engage with brands and to enjoy highly positive brand experiences…</p> 
          </div>
          <div class="_experience_info__box _info_box">
            <h2 class=" _info_box__title _info_box__title--small _info_box__title--red" v-html="$t('capabliltieses_title_2')">WE ARE A CONSUMER ACTIVATION COMPANY</h2>
            <p class="_info_box__des" v-html="$t('capabliltieses_des_2')">Consumer Activation is an advanced form of comprehensive communications that motivates consumers to take action. It encompasses a consistent focus on the actual daily lives of consumers and the creation of an array of touchpoints that encourage consumers to engage with brands and to enjoy highly positive brand experiences…</p> 
          </div>
        </div>
      </div>
      <div class="_experience_award _experience_award--normal">
        <div class="_experience_client__bgn"></div>
        <div class="_container _container--large">
          <h2 class="_experience_award__title _experience_award__title--normal">We Work with ...</h2>
        </div>
      </div>

      <div class="_experience_client__boxBgn">
        <div class="_experience_bgn__gray">      
          <svg viewBox="0 0 1487 1557" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-193.000000, -2125.000000)" fill="#F6F6F6">
                      <path d="M952.478368,2227.41694 L1679.80379,3681.5 L1679.80379,3681.5 L123.196212,3681.5 L850.521632,2227.41694 C864.604416,2199.26237 898.844562,2187.85492 926.999137,2201.93771 C938.02389,2207.45224 946.963839,2216.39219 952.478368,2227.41694 Z" id="Triangle" transform="translate(901.500000, 2903.500000) rotate(270.000000) translate(-901.500000, -2903.500000) "></path>
                  </g>
              </g>
          </svg>
        </div>

        <div class="_container">
          <div class="_experience_client">
              <div class="_experience_client__block" v-for="(cate,index) in client" :key="index">

                  <router-link class="_experience_client__item" :to="'/capabilities/'+cate.data[0].category.slug">
                    <div class="_experience_client__itemImg">
                      <img :src="cate.data[0].category.photo" />
                    </div>
                  </router-link>
              </div>
          </div>
        </div>

      </div>
      
      
    </div>
    

    <div class="_experience_award">
      <div class="_experience_award__bgn"></div>
      <div class="_container _container--large">
        <h2 class="_experience_award__title">The Accolades<br/> We Received Worldwide</h2>
      </div>
    </div>
    <div class="_container _container--large">
        <div class="_experience_award__list">
            <swiper :options="awardOptions" class="_experience_award__row d-block">
              <swiper-slide v-for="(col,index) in awardsRe" :key="index" class="">
                
                <div v-for="(item ,index) in col" :key="index" class="_experience_award__item">
                  <a :href="item.link" :target="item.link_type" class="_experience_award__itemCover">
                    <div class="_experience_award__itemImg" v-lazy:background-image="item.photo"></div>
                  </a>
                  <!-- <a :href="item.link" :target="item.link_type" class="_experience_award__itemInfo">
                    <strong class="_experience_award__itemName">{{item.name}}</strong>
                  </a> -->
                </div>
                
              </swiper-slide>

            </swiper>
            
            <!-- <div v-if="awardMore" @click="loadMore" class="_experience_award__more">More ...</div> -->
            <div class="_experience_award__pager _swiper_pagination"></div>
          </div>
        </div>
    
    <transition name="_transition-group" duration="800">
      <router-view :client="client"/>
    </transition>

  </div>
</template>

<script>
//import $ from 'jquery'
import { gsap,TimelineMax,TweenMax  } from 'gsap'
import commonMixin from '../utils/commonMixin'
import $ from 'jquery'
export default {
  name: 'Experience',
  data:function(){
      return{
        bannerData:[],
        imageData:[],
        client:[],
        awards:[],
        clientDetail:{},
        clientDetail_show:false,
        awardsRe:[],
        limit:9,
        awardMore:false,
        bgn_1:{},
        bgn_2:{},
        clineSence:{},
        awardSence:{},
        page:1,
        open:-1,
        clientOptions:{
          slidesPerView: 4,
          slidesPerColumn: 2,
          slidesPerColumnFill : 'column',
          pagination: {
            clickable: true,
            el: '._experience_client__pager',
          },
          breakpoints: {
            800: {
              slidesPerView: 4,
            },
            640: {
              slidesPerView: 2,
            },
            480: {
              slidesPerView: 2,
            }
          }
        },
        awardOptions:{
          slidesPerView: 5,
          slidesPerGroup: 5,
          //slidesPerColumn: 3,
          //slidesPerColumnFill : 'column',
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            clickable: true,
            el: '._experience_award__pager',
          },
          breakpoints: {
            800: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            640: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            480: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            }
          }
        }
      }
  },
  // beforeRouteEnter (to, from, next) {
  //   this.$store.commit('setLoading',true);
  //   console.log('Experience beforeRouterEnter.');
  //     next();
  // },
  // beforeRouteUpdate (to, from, next) {
  //   console.log('Experience beforeRouteUpdate.');
  //     next();
  // },
  mounted:function(){
    var _this=this;
    this.getAPIClient();
    this.getAPIAwards();
    this.initScrollMagic();
    window.addEventListener("resize", this.winResize);
    // if(!this.$store.state.bannerData){
    //   this.loadBanner('home',false)
    // }
  },
  methods:{
    getAPIClient() {
      this.axios({
        method: "get",
        url: "/api/client",
      })
        .then(response => {
          var _this=this
          //this.client=response.data
          console.log('client::',response.data.items);
          this.reMakeClient(response.data.items);
          // setTimeout(function(){
          //   _this.checkClientWidth();
          // },500)
        })
        .catch(function(err) {
          //console.log(err);
        });
    },
    
    getAPIAwards() {
      //console.log( "/api/work/prizes?+limit="+this.limit+'&page='+this.page)
      var _this=this
      this.axios({
        method: "get",
        url: "/api/banner?category_slug=capabilities"
      })
      .then(response => {
        for(let i in response.data.items){
            this.imageData.push(response.data.items[i].photo)
          }
          this.bannerData=response.data.items
          //console.log('bannerData',this.bannerData)
          //_this.$store.commit('setBannerData',data)
          this.preloadImages(this.imageData,function(){
            _this.$store.commit('setLoading',false);
            _this.tweenText($('._banner_kv__headLine'),true)
            _this.initBanner();
            //console.log("allImageLoaded!!")
          })
      })
      .catch(function(err) {
        console.log(err);
      });

      this.axios({
        method: "get",
        url: "/api/work/prizes",
      })
        .then(response => {
          //console.log(response);
          if(!this.awards.items){
            this.awards=response.data;
            this.reorgPrize();
          }else{
            for(let i in response.data.items){
              this.awards.items.push(response.data.items[i])
            }
          }
          if(Number(response.data.pagination.current_page)== Number(response.data.pagination.total_page)){
            this.awardMore=false
          }else{
            this.awardMore=true
          }
          //console.log(this.awardMore,this.page,response)

        })
        .catch(function(err) {
          console.log(err);
        });
    },

    reMakeClient:function(arr){
      console.log('reMakeClient!!')

      var  map = {}
      this.client=[]
      for(let i in arr){
          var  item = arr[i];
          //console.log(this.getPublishDay(item.publish_date))
          if (!map[item.category.name]){
              this.client.push({
                  category:item.category.name,
                  slug:item.category.slug,
                  data: [item]
              });
              map[item.category.name] = item;
          } else {
              for ( let  j in this.client){
                  var  listItem = this.client[j];
                  if (listItem.category == item.category.name){
                      listItem.data.push(item);
                      break ;
                  }
              }
          }
      } 

      console.log('remakeClient::::',this.client)
    },

    clientClick:function(deta){
      this.clientDetail=deta
      this.clientDetail_show=true
      $('html').addClass('navOpen')
    },
    clientClose:function(){
      this.clientDetail_show=false
      this.clientDetail={}
      $('html').removeClass('navOpen')
    },

    checkClientWidth:function(num){
      $('._experience_client__block').each(function(){
        if($(this).hasClass('active'))
        {
          $(this).find('._experience_client__body').height($(this).find('._experience_client__bodyCon').outerHeight())
        }else{
          $(this).find('._experience_client__body').height($(this).find('._experience_client__item').eq(0).outerHeight())
        }
        if($(this).find('._experience_client__item').eq(0).outerHeight()==$(this).find('._experience_client__bodyCon').height()){
         // $(this).find('._experience_client__cateIcon').hide();
         $(this).addClass('opened')
          console.log('height:::',$(this).find('._experience_client__item').height());
        }else{
          $(this).removeClass('opened')
          //$(this).find('._experience_client__cateIcon').show();
        }
      })
      
    },

    winResize:function(){
      //this.checkClientWidth();
    },

    loadMore:function(){
      this.page+=1;
      this.getAPIAwards()
    },
    initScrollMagic:function(){

        this.bgn1 = this.$scrollmagic.scene({
          triggerElement:'._experience_bgn',
          duration:'200%',
          triggerHook: 1
          }).setTween("._experience_bgn__red", {y: '-10vw'});
        this.$scrollmagic.addScene(this.bgn1);

      //  this.bgn2 = this.$scrollmagic.scene({
      //     triggerElement:'._experience_bgn',
      //     duration:'300%',
      //     triggerHook: 1
      //     }).setTween("._experience_bgn__gray", {y:'-40vw'});
      //   this.$scrollmagic.addScene(this.bgn2);

        this.clientSence = this.$scrollmagic.scene({
          triggerElement:'._experience_client__bgn',
          duration:'200%',
          triggerHook: 1
          }).setTween($("._experience_client__bgn"), {css:{'background-position-y':'0vw'}});
        this.$scrollmagic.addScene(this.clientSence);

        this.awardSence = this.$scrollmagic.scene({
            triggerElement:'._experience_award',
            duration:'200%',
            // triggerElement: '._banner_kv',
            triggerHook: 1
        }).setTween('._experience_award__bgn',{css:{
            'background-position-y':'-10vw'
        }})
        this.$scrollmagic.addScene(this.awardSence);
    },
    reorgPrize:function(){
      var gNum=0;
      var dNum=-1;
      this.awardsRe=[];
      //this.awardsRe[0]＝["asds"];
      for(var i=0,len=this.awards.items.length;i<len;i+=1){
         this.awardsRe.push(this.awards.items.slice(i,i+1));
      }
      // for(let i in this.awards.items){
      //   if(dNum<2){
      //     dNum++;
      //   }else{
      //     dNum=0;
      //     gNum++;
      //    // this.awardsRe[gNum]＝[]
      //   }
      //   this.awardsRe[gNum].push(this.awards[i]);  
      //   //console.log('i::',this.awards)
      //   console.log('gNum::',gNum);
      //   console.log('d::',dNum);
      //   console.log('awardsRe::',this.awardsRe)
      // }
      
      //console.log('awardsRe::',this.awardsRe);
    }
      
  },
  destroyed () {
    this.destoryBanner()
    this.bgn_1.destroy(true) 
    this.bgn_2.destroy(true) 
    this.clientSence.destroy(true) 
    this.awardSence.destroy(true) 
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setLoading',true)
    next()
  }
}
</script>
