import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navState:false,
    navTween:false,
    loading:false,
    next:{},
    landing:true,
    lang:window.$cookies.get('lang_code') ? window.$cookies.get('lang_code') : 'tw',
    defaultLang:'tw',
    bannerData:{
      home:[],
      group:[],
      experience:[],
      work:[],
      news:[],
      content:[]
    }
  },
  mutations: {
    setNavState(state,deta){
      state.navState=deta;
    },
    setNavTween(state,deta){
      state.navTween=deta;
    },
    setLoading(state,deta){
      //console.log('setLoading:'+ deta);
      state.loading=deta;
    },
    setBannerData(state,deta){
      //console.log('setLoading:'+ deta);
      state.bannerData.deta=deta;
    },
    setNext(state,deta){
      //console.log('setLoading:'+ deta);
      state.next=deta;
    },
    setLanding(state,deta){
      //console.log('setLoading:'+ deta);
      state.landing=deta;
    },
    setLang(state,data){
      state.lang=data;
    }
  }
})