<template>
  <div class="_works">
    <div class="_banner_kv _works_banner">
        <div class="_banner_kv__bg">
          <div class="_banner_kv__bgZoom" >
          <div v-if="bannerData.length" class="_banner_kv__slide">
            <div class="_banner_kv__img _home_banner__img">
              <div v-for="(item, index ) in bannerData" :key="index"  class="_banner_kv__img _home_banner__img">
              <img v-if="item.photo && index < 1" class="_banner_kv__bgImg" :src="item.photo">
              <div v-if="item.video && index < 1" class="_banner_kv__iframe">
                <youtube :video-id="item.video.video_id" ref="youtube" :player-vars="{autoplay: 0,controls:0,loop:1,playlist:item.video.video_id,rel:0,volumn:0}"  @ready="videoReady"></youtube>
              </div>
            </div>
            </div>
          </div>
          </div>
        </div>
        <div class="_banner_kv__container _container">
          <div v-if="bannerData.length" class="_banner_kv__info _banner_kv__info--center" :class="[ $store.state.loading ? '' : 'active']">
            <strong class="_banner_kv__name" :class="[ $store.state.loading ? '' : 'active']">{{ bannerData[0].category.name }}</strong>
            <h1 class="_banner_kv__headLine _banner_kv__headLine--middle">{{bannerData[0].name}}</h1>
          </div>
          <button v-scroll-to="'#main'" class="_banner_kv__scrollDown _btn_hollow _btn_hollow--red _btn_hollow--radius _font_btn font-weight-bold">Scroll Down</button>
        </div>
    </div>

    <div id="main" class="_main _works_main">
      <div class="_works_main__bgn">
      </div>
      <div class="_container">
        <div  class="_works_filter">
          <div v-if="gategory.length" class="_works_filter__category">
            <div class="_select">
              <select @change="changeCategory(category)" v-model="category" class="_select_list">
                <option value="">{{$t('work_gategory')}}</option>
                <option v-for="(item ,index) in gategory" :key="index" :value="item.slug">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="_works_filter__order _tab">
            <div @click="changeSort(true)" class="_works_filter__orderItem _tab__item " :class="{ active: forward }">new</div>
            <div @click="changeSort(false)" class="_works_filter__orderItem _tab__item" :class="{ active: !forward }">before</div>
          </div>
        </div>
      </div>
      
        <div v-if="getWorkList.length" class="_works_list">
          <div v-for="(row,index) in getWorkList " :key="index" class="_works_list__row _container">

            <div v-for="(item,index) in row" :key="index" class="_works_list__rowItem _works_item _card _card--hover">

                  <router-link :to="{ name:'WorksDetail',params:{slug:item.slug}}">
                    <div class="_works_item__cover  _card_cover--gray">
                      <img class="_home_works__cardImg _card_cover__img" v-lazy="item.list_photo">
                    </div>
                  </router-link>
                  <router-link class="_works_item__des _card_des _card_des--gray" :to="{ name:'WorksDetail',params:{slug:item.slug}}">
                  <!-- <h3 v-html="item.name" class="_works_item__title _card_title"></h3> -->
                    <h3 >{{item.sub_title}}<span class="_card_des__span">{{item.name}}</span></h3>
                    <div v-if="item.labels != null" class="_card_lables">
                      <span v-for="(lebal,index) in getWorkLebal(item.labels)" :key="index">{{lebal}}</span>
                    </div>
                  </router-link>
                  
                  <div class="_card_icons">
                    <div v-for="(item,index) in item.prize" :key="index" class="_works_item__award _card_icons__icon">
                        <img :src="item.photo"/>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      
    </div>
    <div class="_works_contact">
        <div class="_works_contact__container _container">
          <h2 class="_works_contact__text">
            WE ARE ALWAYS ON THE LOOKOUT FOR GREAT COLLABORATORS<br/><router-link to="/contact">CONTACT US</router-link>
          </h2>
        </div>
    </div>

  </div>
</template>
<script>
import commonMixin from '../utils/commonMixin'
import $ from 'jquery'
import { gsap,TimelineMax,TweenMax  } from 'gsap'
export default {
  name: 'Works',
  data:function(){
      return{
        bannerData:[],
        imageData:[],
        gategory:[],
        worksData:[],
        category:'',
        forward:true,
        workList:[]
        //isLoading:this.$store.state.loading
      }
  },
  mounted:function(){
    var _this=this;
    this.getApiCategory('/categorys');
    //this.category=
    //console.log(this.$route)
    // if(!this.$store.state.bannerData){
    //   this.loadBanner('work',false)
    // }
    
  },
  methods:{
    getApiCategory() {
      var _this=this
      this.axios({
        method: "get",
        url: "/api/banner?category_slug=works"
      })
      .then(response => {
        for(let i in response.data.items){
            this.imageData.push(response.data.items[i].photo)
          }
          this.bannerData=response.data.items
          //console.log('bannerData',this.bannerData)
          //_this.$store.commit('setBannerData',data)
          this.preloadImages(this.imageData,function(){
            _this.$store.commit('setLoading',false);
            _this.tweenText($('._banner_kv__headLine'),true)
            _this.initBanner();
            //console.log("allImageLoaded!!")
          })
      })
      .catch(function(err) {
        console.log(err);
      });

      this.axios({
        method: "get",
        url: "/api/work/categorys"
      })
        .then(response => {
          //console.log(response);
         //console.log(this.$route.query.slug);
          this.gategory = response.data.items;
          //this.getApiWorks('');
          
          this.getApiWorksList()
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    getApiWorks(slug) {
      
      this.axios({
        method: "get",
        url: "/api/work"+slug
      })
        .then(response => {
          //console.log(response);
          //console.log('$route:::',this.$route);
          //console.log(this.$route.query.slug);
          //alert('loaded')
          this.worksData = response.data.items;
          //this.initScrollMagic()
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    changeCategory:function(val){
      this.worksData=[]
      if(val=='' || val==undefined)
      {
        //this.getApiWorks('');
        this.$router.push({ path: '/work'}).catch(err => {})
      }else{
        //this.getApiWorks('?category_slug='+val);
        this.$router.push({ path: '/work?category='+val }).catch(err => {})
      }
      //console.log('$route:::',this.$route);
      //console.log(val)  
    },
    changeSort(val){
      if(this.forward!=val){
        this.forward=val;
        this.worksData.reverse();
        //console.log(this.worksData)
      }
    },
    getApiWorksList:function(){
      //console.log(this.$route)
      if(this.$route.query.category){
        this.getApiWorks('?category_slug='+this.$route.query.category)
      }else{
        this.getApiWorks('')
      }
    },
    initScrollMagic:function(){
      var _this=this;
      //console.log('_this.workList-before:::',_this.workList)
      // if(_this.workList.length>0){
      //   //alert(_this.workList.length);
      //   for(var i in _this.workList){
      //     this.workList[i].destroy(true)   
      //   }
      //   _this.workList=[]
      // }
      setTimeout(function(){
          $('._card_cover__img').each(function(index){
            //alert(index);
            _this['workImg'+index] = _this.$scrollmagic.scene({
                triggerElement:this,
                triggerHook:1,
                duration:'200%'
            })
            .setTween($(this), {css:{
              'transform':'scale(1,1)'
            }})
            _this.$scrollmagic.addScene(_this['workImg'+index]);
            var workItem= _this['workImg'+index]
            _this.workList.push(workItem)
          })
      },500)
      
      //console.log('_this.workList-after:::',_this.workList)
    }
  },
  computed: {
    getWorkList:function(){
      let worksList=[]
      let x=0
      let y=0
      for(let i in this.worksData){
        if(!worksList[y])worksList[y]=[];
        worksList[y][x]=this.worksData[i]
        if(x<1){
          x++
        }else{
          y++
          x=0
        }
      }
      return worksList
    }
  },
  watch: {
    '$route' (to, from) {
      if(to!=from){
        //console.log(to)
        this.getApiWorksList()
      }
    }
  },
  destroyed () {
    this.destoryBanner()
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setLoading',true)
    next()
  }
  
}
</script>
