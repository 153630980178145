<template>
  <div class="_News">

    <div class="_banner_kv ">
        <div class="_banner_kv__bg">
          <div v-if="bannerData" class="_banner_kv__bgZoom" >
            <div  v-for="(item, index ) in bannerData" :key="index"  class="_banner_kv__img _home_banner__img">
              <img v-if="item.photo && index < 1" class="_banner_kv__bgImg" :src="item.photo">
              <div v-if="item.video && index < 1" class="_banner_kv__iframe">
                <youtube :video-id="item.video.video_id" ref="youtube" :player-vars="{autoplay: 0,controls:0,loop:1,playlist:item.video.video_id,rel:0,volumn:0}"  @ready="videoReady"></youtube>
              </div>
            </div>
          </div>
        </div>
        <div class="_banner_kv__container _container">
            <div class="_banner_kv__info  _banner_kv__info--center" :class="[ $store.state.loading ? '' : 'active']">
                <strong class="_banner_kv__name" :class="[ $store.state.loading ? '' : 'active']"><span>{{ bannerData[0].category.name }}</span></strong>
                <h1 v-if="bannerData" class="_banner_kv__headLine _banner_kv__headLine--middle">{{bannerData[0].name}}</h1>
            </div>
            <button v-scroll-to="'#main'" class="_banner_kv__scrollDown _btn_hollow _btn_hollow--red _font_btn _btn_hollow--radius font-weight-bold">Scroll Down</button>
        </div>
    </div>
    
    <div id="main" class="_main ">
      <div class="_contact_office">
        <div class="_container">
            <div class="_contact_office__box">
                <div class="_contact_office__info">
                    <h3 class="_contact_office__title">TAIPEI OFFICE INFORMATION</h3>
                    <ul class="_contact_office__list">
                        <li class="_contact_office__item"><span>{{$t('contact_email')}} : </span><a href="mailto:info-adktaiwan@adk.com.tw">info-adktaiwan@adk.com.tw</a></li>
                        <li class="_contact_office__item"><span>{{$t('contact_tel')}} : </span><a href="tel:81-11-746-1311">{{$t('contact_tel_content')}}</a></li>
                        <li class="_contact_office__item"><span>{{$t('contact_address')}} : </span><a href="https://www.google.com/maps/place/ADK+Taiwan/@25.0520849,121.546199,15z/data=!4m5!3m4!1s0x0:0xe0f0b351cf766728!8m2!3d25.0520849!4d121.546199" target="_blank">{{$t('contact_address_content')}}</a></li>
                    </ul>
                </div>
                <div class="_contact_office__btns">
                    <a class="_contact_office__link _btn_solid _btn_solid--white _btn_solid__hover _btn_solid__hover--bar" href="https://www.google.com/maps/place/ADK+Taiwan/@25.0520849,121.546199,15z/data=!4m5!3m4!1s0x0:0xe0f0b351cf766728!8m2!3d25.0520849!4d121.5461997" target="_blank"><span>{{$t('contact_map')}}</span></a>
                    <a class="_contact_office__link _btn_solid _btn_solid--white _btn_solid__hover _btn_solid__hover--bar" href="mailto:info-adktaiwan@adk.com.tw" target="_blank"><span>{{$t('contact_mailto')}}</span></a>
                </div>
            </div>
        </div>
      </div>
        
      <a href="https://www.google.com/maps/place/ADK+Taiwan/@25.0520849,121.546199,15z/data=!4m5!3m4!1s0x0:0xe0f0b351cf766728!8m2!3d25.0520849!4d121.546199" class="_contact_office__map" target="_blank">

      </a>
    </div>

  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'Contact',
  data:function(){
      return{
        bannerData:null,
        imageData:[]
          //isLoading:this.$store.state.loading
      }
  },
  // beforeRouteEnter (to, from, next) {
  //   this.$store.commit('setLoading',true);
  //   console.log('Contact beforeRouterEnter.');
  //     next();
  // },
  // beforeRouteUpdate (to, from, next) {
  //   console.log('Contact beforeRouteUpdate.');
  //     next();
  // },
  mounted:function(){
    var _this=this;
    this.getAPIdatas();
    
    // if(!this.$store.state.bannerData){
    //   this.loadBanner('home',false)
    // }
  },
  methods:{
    getAPIdatas() {
      var _this=this
      this.axios({
        method: "get",
        url: "/api/banner?category_slug=contact"
      })
      .then(response => {
        for(let i in response.data.items){
            this.imageData.push(response.data.items[i].photo)
          }
          this.bannerData=response.data.items
          //console.log('bannerData',this.bannerData);
          //_this.$store.commit('setBannerData',data)
          this.preloadImages(this.imageData,function(){
            _this.$store.commit('setLoading',false);
            _this.tweenText($('._banner_kv__headLine'),true)
            //console.log("allImageLoaded!!")
            _this.initBanner();
          })
      })
      .catch(function(err) {
        console.log(err);
      });
    }
      
  },
  destroyed () {
    this.destoryBanner()
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setLoading',true)
    next()
  }
}
</script>
