import Vue from 'vue'

import VueI18n from 'vue-i18n'
//import {defaultLang} from "./lang.json"
import VueCookies from 'vue-cookies'
import en from './langPack/en'
import tw from './langPack/tw'

//var Vue = require('vue');
//var VueCookie = require('vue-cookie');
Vue.use(VueI18n)
Vue.use(VueCookies)

const messages={en,tw};
const i18n = new VueI18n({
    locale: window.$cookies.get('lang_code') ? window.$cookies.get('lang_code') : 'tw',
    messages
});

export default i18n