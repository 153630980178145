//index.js
import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Capabilities from '@/components/Capabilities'
import ClientDetail from '@/components/ClientDetail'
import Works from '@/components/Works'
import WorksDetail from '@/components/WorksDetail'
import About from '@/components/About'
import GroupMember from '@/components/GroupMember'
import News from '@/components/News'
import NewsDetail from '@/components/NewsDetail'
import Contact from '@/components/Contact'
import NotFound from '@/components/NotFound'
import $ from 'jquery'

Vue.use(Router)
const router = new Router({
  mode:'history', 
  base: BASE_URI,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      props: true,
      meta:{
        title:window.vueMeta.META_ABOUT_TITLE,
        description:window.vueMeta.META_ABOUT_DES,
        requireAuth:true
      },
      children:[
      {
        path: ':slug',
        component:GroupMember,
        props: true
      }
      ]
    },
    {
      path: '/capabilities',
      name: 'Capabilities',
      component: Capabilities,
      props: true,
      meta:{
        title:window.vueMeta.META_CAPABILITIES_TITLE,
        description:window.vueMeta.META_CAPABILITIES_DES,
        requireAuth:true
      },
      children:[
        {
          path: ':slug',
          component:ClientDetail,
          props: true
        }
      ]
    },
    {
      path: '/work',
      name: 'Works',
      component: Works,
      props:true,
      meta:{
        title:window.vueMeta.META_WORKS_TITLE,
        description:window.vueMeta.META_WORKS_DES,
        requireAuth:true
      }
    },
    {
      path: '/work/:slug',
      name: 'WorksDetail',
      component: WorksDetail,
      props:true,
      meta:{
        title:window.vueMeta.META_WORKS_TITLE,
        description:window.vueMeta.META_WORKS_DES,
        requireAuth:true
      }
    },
    {
      path: '/news',
      name: 'News',
      props: true,
      component: News,
      meta:{
        title:window.vueMeta.META_NEWS_TITLE,
        description:window.vueMeta.META_NEWS_DES,
        requireAuth:true
      },
      children:[
      {
        path: ':slug',
        component:NewsDetail,
        props: true
      }
      ]
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      meta:{
        title:window.vueMeta.META_CONTACT_TITLE,
        description:window.vueMeta.META_CONTACT_DES,
        requireAuth:true
      }
    },
    // {
    //   path: '*',
    //   name: 'NotFound',
    //   redirect: '/404',
    //   component: NotFound
    // }
  ],
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      //console.log('route::',to);
      if(to.matched.length < 2){
        if(to.matched[0]!=from.matched[0]){
          setTimeout(() => {
            resolve({ x: 0, y: 0 })
          }, 800)
        }else if(from.name=='WorksDetail'){
          setTimeout(() => {
            resolve({ x: 0, y: 0 })
          }, 800)
        }
        $('html').removeClass('navOpen')
      }else{
        $('html').addClass('navOpen')
      }
    })
  }
  
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
})

// router.beforeResolve((to, from, next) => {
//   console.log("router.beforeResolve")
//   console.log(this)
//   //next();
// })


// router.afterEach((to, from, next) => {
//   console.log("router.afterEach")
  
// })

export default router;

  