
<template>
  <div class="_sidePop _sidePop--large _sidePop--largeMore _sidePop--white">
      <div class="_sidePop__box _transition_slideLift">
        <div class="_sidePop__boxSide"></div>
        <div  class="_sidePop_content">
          <div class="_sidePop__bgn ">
          </div>
          <!-- <strong class="_sidePop__em">{{filterData.category}}</strong> -->
          <!-- <h2 class="_sidePop__title">{{filterData.category}}</h2> -->
          <img class="_sidePop__photo _sidePop__photo--noneBorder" :src="filterData.data[0].photo"/>
        </div>
        <router-link class="_sidePop__close" to="/capabilities">
            <div class="_header_trigger__icon">
              <span class="_header_trigger__iconLine _header_trigger__iconLine--close _sidePop__closeLine"></span>
            </div>
        </router-link>  
      </div>
  </div>
</template>


<script>
import $ from 'jquery'
export default {
  name: 'ClientDetail',
  props: {
    client:Object,
  },
  data:function(){
      return{
        detail:Object
      }
  },
  mounted:function(){
    var _this=this;
    setTimeout(function(){
      $('._sidePop').addClass('active')
      console.log('_this.client:::',_this.client)
    },300)
  },
  computed: {
    filterData: function () {
      var _this=this
      //console.log('this.news::::',_this.news.find)
      if(_this.client.find){
        return _this.client.find(function(item, index, array){
          return item.slug == _this.$route.params.slug
        })
      }
      return false  
    } 
  },
  destroyed () {
    this.destoryBanner()
  },
  beforeRouteLeave(to, from, next) {
    $('._sidePop').removeClass('active')
    next()
  }
  

}
</script>
