/* commonMixin.js */
//import { TweenMax } from 'gsap'
import $ from 'jquery'
import router from "../router"
import { gsap,TimelineMax,TweenMax  } from 'gsap'
import i18n from '@/libs/i18n/lang'

export default {
    data:function(){
        return {
            baseUri: BASE_URI,
            cookiePrefix: COOKIE_PREFIX,
            logo:{},
            bannerSence:{}
        }
    },
    methods: {
        initBanner:function(){
            //console.log('this.logo',this.logo)
            this.bannerSence = this.$scrollmagic.scene({
                triggerElement:'._banner_kv',
                duration:'100%',
                triggerHook: 0
            })
            .setTween('._banner_kv__bg',{css:{
                'transform': 'translate3d(0px, 20%, 0px) scale(1,1)'
            }})
            this.$scrollmagic.addScene(this.bannerSence)
           
            // this.logo = this.$scrollmagic.scene({
            //     triggerElement:'._banner_kv',
            //     duration:'100%',
            //     triggerHook: 0,
            //     offset:-50
            // })
            // .setClassToggle('._header_logo__txt','white')
            // .addIndicators() 
            // this.$scrollmagic.addScene(this.logo)

            this.scroll = this.$scrollmagic.scene({
                triggerElement:'._main',
                triggerHook:.98
            })
            .setClassToggle('._banner_kv__scrollDown','hide')
            this.$scrollmagic.addScene(this.scroll)
            TweenMax.fromTo('._banner_kv__bgZoom',8,{scale:1.3},{scale:1});
        },
        destoryBanner:function(){
            //console.log('destoryBanner:::',this.logo.destroy);
            if(this.bannerSence.destroy)this.bannerSence.destroy(true)   
            if(this.logo.destroy)this.logo.destroy(true)   
        },
        getPublishYear:function(dateString){
            var date = new Date(Number(dateString)*1000);
        return date.getFullYear();
        },
        getPublishMonth:function(dateString){
            var date = new Date(Number(dateString)*1000);
        return date.getMonth();
        },
        getPublishDay:function(dateString){
            var date = new Date(Number(dateString)*1000);
            //console.log("dateString:",dateString,"DATE::",date);
        return date.getDate();
        },
        preloadImages:function(urls, allImagesLoadedCallback){
            var loadedCounter = 0;
            var toBeLoadedNumber = urls.length;
            urls.forEach(function(url){
                preloadImage(url, function(){
                    loadedCounter++;
                        //console.log('Number of loaded images: ' + loadedCounter);
                if(loadedCounter == toBeLoadedNumber){
                    allImagesLoadedCallback();
                }
                });
            });
            function preloadImage(url, anImageLoadedCallback){
                var img = new Image();
                img.onload = anImageLoadedCallback;
                img.src = url;
            }
            
        },
        checkLang:function($url){
            var url='';
            var path=$url.replace(location.protocol +'//'+ location.hostname, '');
            if(this.$t('global.currentLang.type')=='tw'){
                url =location.protocol +'//'+ location.hostname;
            }else{
                url = location.protocol +'//'+ location.hostname + '/'+this.$t('global.currentLang.type');
            }
            return path;
        },
        tweenText:function($elm,rendom){
            //console.log('$elm:',$elm.text().length)
            var orgStr=$elm.text()
            var str = $elm.text().split('')
            var inject=''
            var elmArr=[]
            var delayTime=.02
            var aniTime=.7
            var startTime=1
            //console.log(str)
            TweenMax.set($elm,{alpha:0})
            if (str.length) {
                $(str).each(function(i, item) {
                    inject += '<span>'+item+'</span>';
                });	
                $elm.empty().append(inject);
            }
            $elm.find('span').each(function(index){
                elmArr.push($(this))
            })
            if(rendom){
                elmArr.sort(function() {
                    return (0.5-Math.random());
                });
            }
            setTimeout(function(){
                TweenMax.set($elm,{alpha:1})
                $elm.find('span').each(function(index){
                    //console.log(elmArr[index].text())
                    $(this).css({'opacity':0})
                    TweenMax.to(elmArr[index],aniTime,{alpha:1,delay:startTime+index*delayTime})
                    if(index==elmArr.length-1){
                        TweenMax.to(elmArr[index],aniTime,{alpha:1,delay:startTime+index*delayTime,onComplete:function(){
                            $elm.text(orgStr)
                        }})
                    }
                })
            },1000)
        },
        videoReady:function(target){
            this.$refs.youtube[0].player.playVideo()
            this.$refs.youtube[0].player.mute()
            $('._banner_kv__iframe').addClass('playing')
            if(navigator.userAgent.match(/iPhone|iPod/i)){
                $('._banner_kv__iframe').addClass('hide')
            }
            console.log(target)
        },
        setLang:function(lang){
            var url=location.protocol +'//'+ location.hostname;
            
            if(window.$cookies.get('lang_code')=='tw' && lang=='en')
            {
                url.replace("/tw/","");
            }else if(window.$cookies.get('lang_code')=='en' && lang=='tw'){
                url.replace("/en/","");
            }
            window.location = url + '/'+ lang + this.$route.fullPath;
        },
        getWorkLebal:function(lebals){
            //console.log(lebals.split(','))
            return lebals.split(',')
        },
        removeSmarollMargic:function(){
            
        }
    }
}