<template>
  <div class="_worksDetail">
    <div class="_banner_kv__red">
      <div class="_banner_kv__red-1">
        <img src="/img/bgn-works-1.svg"/>
      </div>
      <div class="_banner_kv__red-2">
        <img src="/img/bgn-works-2.svg"/>
      </div>
    </div>
    

    <div class="_banner_kv _worksDetail_banner">
        <div class="_banner_kv__bg">
          <div class="_banner_kv__img _worksDetail_banner__img">
            <div class="_banner_kv__bgZoom" >
              <img class="_banner_kv__bgImg" :src="worksDetail.photo">
            </div>
          </div>
        </div>
        
        <div class="_banner_kv__container _container">
          <div v-if="worksDetail.name" class="_banner_kv__info" :class="[ $store.state.loading ? '' : 'active']">
            <!-- <strong class="_banner_kv__name" :class="[ $store.state.loading ? '' : 'active']">ADK WORKS</strong> -->
            <!-- <h1  v-html="worksDetail.name" class="_banner_kv__headLine _banner_kv__headLine--white _banner_kv__headLine--middle"></h1> -->
          </div>
          <button v-scroll-to="'#main'" class="_banner_kv__scrollDown _btn_hollow _btn_hollow--red _btn_hollow--radius _font_btn font-weight-bold">Scroll Down</button>
        </div>
    </div>

    <div id="main" class="_main _worksDetail_main">
        <div class="_worksDetail_intro">
            <div class="_container _worksDetail_intro__container">
                <div class="_worksDetail_intro__info">
                    
                    <h2 v-if="worksDetail.sub_title" v-html="worksDetail.sub_title" class="_worksDetail_intro__title">Ａrchitecto beatae vitae dicta sunt explicabo, Nemo enim ipsam voluptate oluptas .</h2>
                    <h1  v-html="worksDetail.name" class="_worksDetail_intro__headline"></h1>
                    <p v-if="worksDetail.description"  v-html="worksDetail.description" class="_worksDetail_intro__des"></p>
                    
                    <div v-if="worksDetail.prize"  class="_worksDetail_intro__icons _card_icons">
                        <div v-for="(item, index) in worksDetail.prize" :key="index" class="_works_item__award _card_icons__icon">
                            <img :src="item.photo"/>
                        </div>
                    </div>

                    <!-- <div v-if="worksDetail.labels != null" class="_worksDetail_intro__lables _card_lables">
                      <span v-for="(lebal,index) in getWorkLebal(worksDetail.labels)" :key="index">{{lebal}}</span>
                    </div> -->
                    
                    <a v-if="worksDetail.link" :href="worksDetail.link" :target="worksDetail.link_type" class="_worksDetail_intro__link _btn _btn_hollow _btn_hollow--white">WATCH</a>
                </div>
            </div>
        </div>
        
        <div class="_editor">
          <div v-html="worksDetail.details">
          </div>
        </div>
        <div class="_worksDetail_recommend">
          <div v-if="recommoned[0]" class="_worksDetail_recommend__item">
              <router-link :to="{ name:'WorksDetail',params:{slug:recommoned[0].slug}}" class="_worksDetail_recommend__link">
                <span class="_worksDetail_recommend__lable">PREV</span>
                <h3 class="_worksDetail_recommend__name">{{recommoned[0].sub_title}}<span>{{recommoned[0].name}}</span></h3>
              </router-link>
          </div>
          <div v-if="recommoned[1]" class="_worksDetail_recommend__item">
              <router-link :to="{ name:'WorksDetail',params:{slug:recommoned[1].slug}}" class="_worksDetail_recommend__link">
                <span class="_worksDetail_recommend__lable">NEXT</span>
                <h3 class="_worksDetail_recommend__name">{{recommoned[1].sub_title}}<span>{{recommoned[1].name}}</span></h3>
              </router-link>
          </div>
        </div>
    </div>
    

  </div>
</template>
<script>

import commonMixin from '../utils/commonMixin'
import VueYoutube from 'vue-youtube'
import $ from 'jquery'

export default {
  name: 'WorksDetail',
  data:function(){
      return{
        worksArr:[],
        worksDetail:[],
        recommoned:[],
        timeout:{},
        workBgnSence:{}
        //isLoading:this.$store.state.loading
      }
  },
  mounted:function(){
    var _this=this;
    this.getApiWorksDetail(this.$route.params.slug);
    //console.log(this.$route.params.slug);
  },
  methods:{
    getApiWorksDetail(slug) {
      var _this=this
      this.axios({
        method: "get",
        url: "/api/work/item?slug="+slug
      })
        .then(response => {
         // console.log(response);
          this.worksDetail = response.data;
          this.preloadImages([_this.worksDetail.photo],function(){
            _this.$store.commit('setLoading',false);
            _this.tweenText($('._banner_kv__headLine'),true)
            _this.initBanner();
            _this.initScrollMagic();
            _this.getApiWorks();

            // scrollBehavior (to, from, savedPosition) {
            //   return new Promise((resolve, reject) => {
            //         setTimeout(() => {
            //           resolve({ x: 0, y: 0 })
            //         }, 500)
            //   })
            // }
            
            //console.log("allImageLoaded!!")
          })
          //console.log(this.worksDetail)
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    getApiWorks() {
      if(this.worksArr.length<1){
        this.axios({
          method: "get",
          url: "/api/work"
        })
          .then(response => {
            this.worksArr = response.data.items;
            //console.log('recommoned::',response);
            this.getRecommendList();
          })
          .catch(function(err) {
            console.log(err);
          });
      }else{
        this.getRecommendList();
      }
      
    },
    initScrollMagic:function(){
        var _this=this;
        this.workBgnSence = this.$scrollmagic.scene({
          triggerElement:'._worksDetail',
          duration:'400%',
          triggerHook: 1
          }).setTween("._banner_kv__patten", {y:'50vh'});
        this.$scrollmagic.addScene(this.workBgnSence);
    },
    getRecommendList:function(){
      //var myArr=[];
      //this.recommoned=this.worksArr;
      for(let i =0 ;i<this.worksArr.length;i++){
        if(this.worksArr[i].slug == this.worksDetail.slug){
          //if(i){
            //console.log('worksArr::',this.worksArr,'worksArr[i]:::',this.worksArr[i])
            if(this.worksArr[i-1])this.recommoned.push(this.worksArr[i-1])
            if(this.worksArr[i+1])this.recommoned.push(this.worksArr[i+1])
          //}
        }
      }
      //this.recommoned=myArr;
      //console.log('recommoned:::',this.recommoned);
    },
  },
  computed: {
    getWorkList:function(){
      let worksList=[]
      let x=0
      let y=0
      for(let i in this.worksData){
        if(!worksList[y])worksList[y]=[];
        worksList[y][x]=this.worksData[i]
        if(x<1){
          x++
        }else{
          y++
          x=0
        }
      }
      return worksList
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setLoading',true)
    next()
  },
  watch: {
    '$route' (to, from) {
      if(to.name=='WorksDetail'){
        let _this=this;
        this.$store.commit('setLoading',true)
        this.workBgnSence.destroy(true)
        
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function(){
          _this.worksArr=[];
          _this.worksDetail=[];
          _this.recommoned=[];
          _this.getApiWorksDetail(_this.$route.params.slug);
          //_this.$root.reload();
        }, 900);
        
      }
    }
  },
  destroyed () {
    this.workBgnSence.destroy(true)
    this.destoryBanner()
    //alert('dostory!');
  }
  
}
</script>
