import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookie from 'vue-cookies'
import axios from 'axios'
import VueMeta from 'vue-meta'
import VueAxios from 'vue-axios'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueScrollmagic from 'vue-scrollmagic'
import VueScrollTo from 'vue-scrollto'
import commonMixin from './utils/commonMixin'
import VueYoutube from 'vue-youtube'
import VueLazyload from 'vue-lazyload'
import i18n from './libs/i18n/lang'
import $ from 'jquery'

// 其它套件

// 自訂語言檔
import en from './libs/i18n/langPack/en'
import tw from './libs/i18n/langPack/tw'
// css
import 'swiper/dist/css/swiper.css'

Vue.config.productionTip = false
Vue.use(VueCookie)
Vue.use(VueAxios,axios)
Vue.use(VueAwesomeSwiper, /* { default global options } */ )
Vue.use(VueScrollmagic)
Vue.use(VueScrollTo)
Vue.use(VueLazyload, {
  preLoad: 2,
  observer: true,
  observerOptions: {
    rootMargin: '2000px'
  }
})
Vue.use(VueYoutube)
Vue.mixin(commonMixin)


axios.defaults.baseURL = BASE_URL;
axios.defaults.headers={
  'X-Requested-With': 'XMLHttpRequest',
  'content-type':'application/x-www-form-urlencoded'
}
//Vue.use(VueYouTubeEmbed,{ global: true, componentId: "youtube-media" })
window.$ = $

new Vue({
  router,
  store,
  i18n,
  //ScrambleText,
  //mixins:[commonMixin],
  render: h => h(App)
}).$mount('#app')
