<template>
  <div class="_sidePop">
      <div v-if="filterTeam" class="_sidePop__box _transition_slideLift">
        <div class="_sidePop__boxSide"></div>
        <div  class="_sidePop_content">
          <div class="_sidePop__bgn ">
            <img :src="filterTeam.photo" />
          </div>
          <strong class="_sidePop__em">{{ filterTeam.job_title }}</strong>
          <h3 class="_sidePop__name">{{ filterTeam.name }}</h3>
          <div class="_sidePop__des" v-html="filterTeam.description"></div>
        </div>
        <router-link class="_sidePop__close" to="/about">
            <div class="_header_trigger__icon">
              <span class="_header_trigger__iconLine _header_trigger__iconLine--close _sidePop__closeLine"></span>
            </div>
        </router-link>  
      </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'GroupMember',
  props: {
    team:{}
  },
  data:function(){
      return{
        member:Object
      }
  },
  mounted:function(){
    var _this=this;
    //this.getAPIdatas();
    //this.initBanner();
    //console.log('team',this.team);
    this.getMember()
    setTimeout(function(){
      $('._sidePop').addClass('active')
    },300)
    
  },
  methods:{
    getMember:function(){
      //this.member=this.filterMember();
      //console.log(this.member);
    },
    filterMember:function(){
      var _this=this
      return _this.member.find(function(item, index, array){
        return item.slug == _this.$route.params.slug
      })
    }
  },
  computed: {
    filterTeam: function () {
      //console.log('this.team',this.team.find)
      var _this=this
      if(this.team.find){
        return _this.team.find(function(item, index, array){
          return item.slug == _this.$route.params.slug
        })
      }
      return false  
    } 
  },
  destroyed () {
    this.destoryBanner()
  },
  beforeRouteLeave(to, from, next) {
    $('._sidePop').removeClass('active')
    next()
  }
  

}
</script>
