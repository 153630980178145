<template>
  <div id="app" :class="{'navOpen':$store.state.navState}" class="_global_app">
    <Header/>
    <div class="_global_body">
        <div class="_global_main">
          <transition
           name="_transition" duration="800" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
        <div class="_global_footer">
          <Footer/>
        </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  data:function(){
    return {
    }
  },
  mounted:function(){
    this.$store.commit('setLoading',true);
  },
  methods:{
   
  },
  watch: {
    '$route' (to, from) {
      if(to!=from){
        //this.$store.commit('setNavState',false);
        if(this.$route.matched.length<2){
          if(to.matched[0]!=from.matched[0]){
            this.$store.commit('setLoading',true);
          }
        }
        //console.log('$route',to);
        //console.log('watch $route : to!=from',this.$store.state.loading);
      }
    }
  },
  
}
</script>
<style lang="scss" src="@/assets/css/app.scss"></style>