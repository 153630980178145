const lang={

    /////////////////////////////////
    //nav
    /////////////////////////////////
    nav_home:'HOME',
    nav_about:'ABOUT',
    nav_capabilities:'CAPABILITIES',
    nav_work:'WORKS',
    nav_news:'NEWS',
    nav_contact:'CONTACT US',


    /////////////////////////////////
    //home
    /////////////////////////////////
    
    home_info_title:"ADK TAIWAN is an advertising agency full of creative energy.",
    home_info_des:"We exceed in leading strong enthusiasm and creative energy and transforming them into insightful and brilliant brand ideas in its creative products and strategies. Working closely with clients to create successful campaigns that has deeply impressed our target consumers. We enrich client’s brand power by combining creativity with communication efficiency to reach maximum impact.<br/><br/>ADK TAIWAN's creative strength has received great accolades of many world-class creative awards. It's the only creative agency in Taiwan that has won Cannes Gold Lion and Silver Lion. It was awarded Asia's hottest top 20 creative agency for four consecutive years by Campaign Brief Asia Creative Ranking (2016-2020), and Agency of the Year for the third time at ONE SHOW Greater China Creative Award.<br/><br/>All members of ADK TAIWAN are consistently goal driven by creativity, connecting consumers and brands, through digital and offline communication, to create more profound, extensive and sustainable brand vision.",
    home_info_watch:"WATCH",
    
    
    /////////////////////////////////
    //about
    /////////////////////////////////

    about_intro:`ADK TAIWAN is an advertising agency full of creative energy.<br/>We exceed in leading strong enthusiasm and creative energy and transforming them into insightful and brilliant brand ideas in its creative products and strategies. Working closely with clients to create successful campaigns that has deeply impressed our target consumers. We enrich client’s brand power by combining creativity with communication efficiency to reach maximum impact`,
    about_title_1:"TECHNOLOGY < HUMANITY",
    about_des_1:"No matter how innovative , technology doesn’t speak for it self. That’s why we use our creativity to give it a voice that can move the world.",
    about_title_2:"CONNECTED < CONTENTED",
    about_des_2:"Being connected isn’t our goal. It’s touching people’s hearts and showing them the fulfillment that comes from how we relate to each other.",
    about_title_3:"ACCELERATOR < CULTURE DRIVER",
    about_des_3:"We believe in creating culture and giving it wings, even if this process sends us on a detour. Because for us, there is no other way to really advance and move the world.",
    about_des_end:`“Consumer Activation" is an advanced form of comprehensive communications that motivates consumers to take action. It encompasses a consistent focus on the actual daily lives of consumers and the creation of an array of touchpoints that encourage consumers to engage with brands and to enjoy highly positive brand experiences. It is also a marketing process that motivates consumers to take specific actions and is directly linked to the business success of clients. <br/><br/>ADK’s communications services do not end with the transmission of a message. We have moved beyond the issue of “how the brand should be positioned in the hearts of consumers” to consider how to “move consumers,” in other words, how to “activate” them.`,
    about_des_end_title:"Our Business Domain",

    /////////////////////////////////
    //Capabliltieses
    /////////////////////////////////

    capabliltieses_title_1:"Brand Value Proposition",
    capabliltieses_des_1:"<strong>Innovative Brand Experience</strong><br/>Unearth insights based on true living behaviors, to transform the brand's purpose into a consumer-centric communication model through innovative ideas, and customized full brand experience journey.<br/><br/><strong>Creativity x Culture Driver</strong><br/>Leading with our world class talented creative team, and copartnering with clients who had always inspired us, we engineer “unskippable” brand stories through innovative thinking and culturally driven contents, deeply roots the brand ’s value into the hearts of consumers.",
    capabliltieses_title_2:`Comprehensive Communication`,
    capabliltieses_des_2:`<strong>Data-driven Strategy</strong><br/>Drawing on extensive data that is based on true human nature with advanced analysis to indeed understand consumers' pain points, and uncover the key of "activating consumer".<br/><br/><strong>Disruption Platform x Optimized Integrated Solutions</strong><br/>Diverge from traditional medium framework, optimize all communication touchpoints between brands and consumers, to provide impactful contents to achieve and sustain brand performance goals.`,
    
    /////////////////////////////////
    //Contact
    /////////////////////////////////

    contact_section_title:'CONTACT',
    contact_email:'EMAIL',
    contact_email_content:'service@adktaiwan.com',
    contact_tel:'TEL',
    contact_tel_content:'886-2-8712-8555',
    contact_address:'ADDRESS',
    contact_address_content:'13f, No.287, Nan-jing E. Rd., Sec.3, Taipei city, Taiwan 105',
    contact_map:'Map',
    contact_mailto:'Mail to ADK TAIWAN',

    ///////////////////////////////
    //work
    ///////////////////////////////
    work_gategory:'Category',
}

export default lang