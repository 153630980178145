<template>
  <div>
    <div class="_global__black" :class="{'open':$store.state.navState,'loading':$store.state.loading}"></div>
    <div class="_loader" :class="{'active':$store.state.loading}">
        <div class="_loader_mask _loader_mask--gray"><span></span></div>
        <div class="_loader_mask _loader_mask--red"><span></span></div>
        <div class="_loader_txt">
          <span>L</span>
          <span>O</span>
          <span>A</span>
          <span>D</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
        </div>
    </div>
    <div class="_nav" :class="{'active':$store.state.navState,'navOut':$store.state.navTween}">
      <div class="_nav_hit" @click="navClose"></div>
      <div class="_nav_box">
        <div class="_nav__triangle">
          <!-- <svg class="_nav__triangle--alpha" viewBox="0 0 839 992" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-263.000000, 0.000000)" fill="#CA0B21">
                      <path d="M1101.897,-0.222 L871.743799,-0.222 L316.588051,557.631289 C246.333316,628.226469 246.333316,742.362374 316.588051,812.957555 L494.57597,991.778 L1101.897,991.778 L1101.897,-0.222 Z" id="Fill-1"></path>
                  </g>
              </g>
          </svg>
          <svg viewBox="0 0 631 992" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-472.000000, 0.000000)" fill="#CA0B21">
                      <path d="M1102.3699,991.6672 L1102.3699,-0.3328 L1101.74582,-0.3328 L510.853982,592.676487 C459.541873,644.171471 459.541873,727.429554 510.853982,778.927033 L722.83503,991.6672 L1102.3699,991.6672 Z" id="Fill-1"></path>
                  </g>
              </g>
          </svg> -->
          <svg class="_nav__triangle--alpha" viewBox="0 0 1216 823" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-222.000000, -165.000000)" fill="#CA0B21">
                    <path d="M1083.164,165 L349.95,529.522 C236.205,586.07 189.839,724.12 246.388,837.863 C268.681,882.704 305,919.044 349.827,941.364 L443.491,988 L1437.999,988 L1437.999,165 L1083.164,165 Z" id="Clip-2"></path>
                  </g>
              </g>
          </svg>
          <svg viewBox="0 0 1041 823" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
              <title>Fill 1</title>
              <desc>Created with Sketch.</desc>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-459.000000, -35.000000)" fill="#CA0B21">
                      <g>
                          <path d="M1291.48235,368.4647 L1680,1145.5 L1680,1145.5 L801,1145.5 L1189.51765,368.4647 C1203.59604,340.307925 1237.8344,328.895138 1265.99117,342.973525 C1277.02224,348.489056 1285.96682,357.433638 1291.48235,368.4647 Z" id="Triangle" transform="translate(1240.500000, 706.000000) rotate(270.000000) translate(-1240.500000, -706.000000) "></path>
                      </g>
                      <path d="M1500,35 L503.840109,533.858332 C464.334185,553.642164 448.34627,601.706007 468.130101,641.211932 C475.865536,656.658662 488.393282,669.186426 503.84,676.921885 L865.284,857.927 L865.284,857.927 L1500,857.927 L1500,35 Z" id="Fill-1"></path>
                  </g>
              </g>
          </svg>

        </div>
        <div class="_nav__out">
          
        </div>
        <div class="_nav__menu">
          <ul class="_nav__menu__list">
            <li class="_nav__menu__link" ><a @click.prevent="navItemClick('/')" href="/" :class="{ active: $route.name == 'Home'}"><span>{{$t('nav_home')}}</span></a></li>
            <li class="_nav__menu__link" ><a @click.prevent="navItemClick('/about')" href="/about" :class="{ active: $route.name == 'About' }"><span>{{$t('nav_about')}}</span></a></li>
            <li class="_nav__menu__link" ><a @click.prevent="navItemClick('/capabilities')" href="/capabilities" :class="{ active: $route.name == 'Capabilities' }"><span>{{$t('nav_capabilities')}}</span></a></li>
            <li class="_nav__menu__link" ><a @click.prevent="navItemClick('/work')" href="/work" :class="{ active: $route.name == 'Works' || $route.name == 'WorksDetail'}"><span>{{$t('nav_work')}}</span></a></li>
            <li class="_nav__menu__link" ><a @click.prevent="navItemClick('/news')" href="/news" :class="{ active: $route.name == 'News' }"><span>{{$t('nav_news')}}</span></a></li>
            <li class="_nav__menu__link" ><a @click.prevent="navItemClick('/contact')" href="/contact" :class="{ active: $route.name == 'Contact' }"><span>{{$t('nav_contact')}}</span></a></li>
          </ul>
          <div class="_nav_language">
              <div class="_nav_language__label" @click="setLang('en')" :class="{ active: $store.state.lang == 'en'}">
                  <span class="_nav_language__btn">EN</span>
              </div>
              <div class="_nav_language__label" @click="setLang('tw')" :class="{ active: $store.state.lang == 'tw'}">
                  <span class="_nav_language__btn">繁</span>
              </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { gsap,TimelineMax,TweenMax  } from 'gsap'
import $ from 'jquery'
export default {
  name: 'Nav',
  data: function() {
    return {
      navOut:false,
      timelineLoader:{},
      timelineNav:{},
      showLoading:false,
      timeOut:Object,
      progress:0,
      winWidth:document.body.clientWidth,
      winW:$(window).width(),
      winH:$(window).height()
      //lang:this.store.state.lang
    }
  },
  mounted:function(){
    //console.log('$store.state.lang ==',this.$store.state.lang)
    //console.log('$store.state.lang ==',window.$cookies.get('lang_code'))
    //alert(this.getWinHeight())
    var _this=this
    window.addEventListener("resize", this.winResize);
    this.translate();
    this.timelineNav = gsap.timeline({paused:true});
      this.timelineNav
      .addLabel("start")
      .to('._nav',.7, {x: -(this.getWinWidth()*1+this.getWinHeight()),ease: "Power4.easeIn",onComplete:function(){
        _this.$store.commit('setLoading',true);
      }})
      .to('._nav',.5, {x: -(this.getWinWidth()*2+this.getWinHeight()*4),ease: "Power4.easeOut",onComplete:function(){
        _this.$store.commit('setNavTween',true)
        if(_this.$store.state.navState) _this.$store.commit('setNavState',false);
      }})
      .set('._nav',{x:0})

  },
  methods: {
    navItemClick:function(page){
      var _this=this
      //console.log(this.$route)
      if(this.$route.path!=page){
        //console.log("this.$route.path");
        this.timelineNav.play('start')
        this.$router.push({path:page})
      }
    },
    isLoading:function(){ 
      var _this=this
        if(!this.$store.state.navState){
          //console.log('loading-start')
          if(this.$store.state.landing){
            this.$store.commit('setLanding',false);
            _this.timelineLoader.play('full');
          }else{
            
            _this.timelineLoader.play('start');
          }
          
        }else{
          //console.log('loading-full')
          //clearTimeout(this.timeOut)
            //this.timeOut=setTimeout(function(){
                _this.timelineLoader.play('start');
            //},500)
          
        }
        this.showLoading=true;
    },
    translate:function(){
      var _this=this
      _this.timelineLoader = gsap.timeline({paused:true});
      _this.timelineLoader
      //window.screen.availHeight
      .addLabel("start")
      .fromTo('._loader_mask--gray',.5,{x:'0px'}, {x:-((window.innerHeight*2)+window.innerWidth+this.getXsclas())  ,ease: "Power2.easeIn"})
      .addLabel("full")
      .fromTo('._loader_mask--red',.5,{x:'0px'}, {x:-((window.innerHeight*2)+window.innerWidth+this.getXsclas()) ,ease: "Power2.easeIn"})
      .addLabel("full-red")
      .fromTo('._loader_mask--red',.5,{x: -((window.innerHeight*2)+window.innerWidth+this.getXsclas())}, {x: -((this.getWinHeight()*4)+window.innerWidth*this.getXmove()+this.getXsclas())  ,ease: "Power1.easeOut",onComplete:function(){
        //console.log('timeline-onCpmplete:',_this.$store.state.loading)
        if(_this.$store.state.loading){
          _this.timelineLoader.play("full");
        }
      }})
      .fromTo('._loader_mask--gray',.5,{x:-((window.innerHeight*2)+window.innerWidth+this.getXsclas()) }, {x:-((this.getWinHeight()*4)+window.innerWidth*this.getXmove()+this.getXsclas())  ,ease: "Power1.easeIn",onComplete:function(){
        _this.timelineLoader.pause()
        _this.$store.commit('setLoading',false);
        _this.showLoading=false
      }})
       _this.timelineLoader.progress(this.progress)
      
    },
    navClose:function(){
      this.$store.commit('setNavState',false);
      $('html').removeClass('navOpen')
    },
    getWinWidth:function(){
      return this.winW
    },
    getWinHeight:function(){
      return this.winH
    },
    getXmove:function(){
      if(this.getWinWidth()>800)
      {
        return 2;
      }else{
        return 2.5;
      }
    },
    getXsclas:function(){
      if(this.getWinWidth()>800)
      {
        return 0;
      }else{
        return 0;
      }
    },
    winResize:function(){
      this.winW=$(window).width();
      this.winH=$(window).height();
      //if(this.$store.state.loading){
        this.progress = this.timelineLoader.progress();
        this.translate();
      //}
    },

    // onWindowResize(e) {
    //   this.winWidth=document.body.clientWidth
    //   return this.winWidth
    //   // your code for handling resize...
    // }
  },
  watch:{
    '$store.state.loading' (){ 
        //console.log('watch',this.$store.state.loading)
        if(this.$store.state.loading){
          this.isLoading(); 
          $('html').addClass('navOpen')
        }else{
          $('html').removeClass('navOpen')
        }

    }
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  
}
</script>
