<template>
  <div class="_footer">
    <div class="_footer_container _container">
      <div class="_footer_left">
        <div class="_footer_logo">
          <svg viewBox="0 0 138 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-247.000000, -134.000000)">
                      <g transform="translate(247.000000, 134.000000)">
                          <path d="M121.693673,14.8351714 L137.740703,7.4222 L137.740703,0.0446857143 L108.510158,14.6860571 C107.131248,15.3767429 106.451976,16.1864286 106.451976,17.0223429 C106.452461,17.8572857 107.131248,18.6679429 108.510158,19.3586286 L137.740703,34 L137.740703,26.622 L121.693673,19.21 C120.344339,18.5868286 119.680582,17.8164857 119.680582,17.0223429 C119.680582,16.2286857 120.344339,15.4583429 121.693673,14.8351714" id="Fill-7" fill="#CA0B21"></path>
                          <path d="M109.929846,0.0446371429 L109.929846,7.42215143 L96.4406154,14.55438 C95.0188308,15.3072371 94.3177846,16.1518943 94.3182769,17.0222943 C94.3182769,17.8926943 95.0188308,18.7378371 96.4406154,19.4897229 L96.4406154,19.4897229 L109.929846,26.6219514 L109.929846,33.9999514 L85.2032,19.9152086 L85.2032,32.6943514 L78.1612308,32.6943514 L78.1612308,1.35072286 L85.2032,1.35072286 L85.2032,14.12938 L109.929846,0.0446371429 Z M53.9211117,1 C65.1980289,1 71.7257721,4.39681703 72.8497189,14.3338705 C73.0500937,16.1051743 73.0500937,17.8943299 72.8497189,19.6656336 C71.7257721,29.6026871 65.1980289,33 53.9211117,33 L53.9211117,33 L39,33 L39,1 Z M17.4035342,0.04455 C18.2281918,0.04455 19.0279178,0.722991176 19.7096986,2.10365294 L19.7096986,2.10365294 L34.8066849,32.6660206 L27.2438082,32.6660206 L19.5629863,15.8049618 C18.9473699,14.4539029 18.1869589,13.7895353 17.4035342,13.7895353 C16.6201096,13.7895353 15.8582603,14.4553588 15.2445616,15.8049618 L15.2445616,15.8049618 L7.56326027,32.6660206 L-9.58904109e-05,32.6660206 L15.0973699,2.10365294 C15.7786712,0.723961765 16.5793562,0.0450352941 17.4035342,0.04455 Z M54.3641734,6.49044645 L45.9571872,6.49044645 L45.9571872,27.5090577 L54.3641734,27.5090577 C61.1195268,27.5090577 64.8420232,25.1263269 65.3896494,18.3227751 C65.460656,17.4420821 65.460656,16.5569261 65.3896494,15.6772249 C64.8420232,8.87317723 61.1195268,6.49044645 54.3641734,6.49044645 L54.3641734,6.49044645 Z" id="Fill-5" fill="#2B3F49"></path>
                      </g>
                  </g>
              </g>
          </svg>
        </div>
      </div>
      <div class="_footer_right">
        <div class="_footer_right__copy">
          <span>© <span class="_footer_right__strong">ADK TAIWAN</span> 2019 all rights reserved</span>
        </div>
        <div class="_footer_right__fb">
          <a class="_footer_right__fbBtn" href="https://www.facebook.com/pages/category/Media-News-Company/ADK-Group-Taiwan-243095972534549/" target="_blank">
            <svg width="4px" height="11px" viewBox="0 0 4 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="M18.872,13.008 L18.632,13.836 C18.392,13.728 18.128,13.668 17.9,13.668 C17.276,13.668 16.976,14.112 16.976,14.952 L16.976,15.984 L18.356,15.984 L18.356,16.872 L16.976,16.872 L16.976,22.5 L15.884,22.5 L15.884,16.872 L14.996,16.872 L14.996,16.044 L15.884,15.984 L15.884,14.94 C15.884,13.62 16.484,12.792 17.78,12.792 C18.176,12.792 18.56,12.876 18.872,13.008 Z" id="path-1"></path>
                </defs>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-329.000000, -1926.000000)" fill-rule="nonzero">
                        <g transform="translate(314.000000, 1914.000000)">
                            <g >
                                <use fill="#222222" xlink:href="#path-1"></use>
                                <use fill="#000000" xlink:href="#path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
