const lang={

    /////////////////////////////////
    //nav
    /////////////////////////////////
    nav_home:'HOME',
    nav_about:'ABOUT',
    nav_capabilities:'CAPABILITIES',
    nav_work:'WORKS',
    nav_news:'NEWS',
    nav_contact:'CONTACT US',


    /////////////////////////////////
    //home
    /////////////////////////////////
    
    home_info_title:"ADK TAIWAN，是一家創意能量豐沛的廣告代理商。",
    home_info_des:"我們擅長透過洞察和策略、將強大的熱情和創意能量，巧妙地轉換成精彩而精準的品牌創意，和客戶一起同心打造讓消費者印象深刻與高度影響力的成功案例，結合傳播效益和創意力，為客戶累積品牌力。<br/><br/>ADK TAIWAN的創意實力，履獲國際指標性創意大獎肯定，是台灣唯一榮獲坎城金獅銀獅的創意代理商，更蟬聯4年Campaign Brief Asia Creative Ranking –亞洲最火創意代理商TOP20 (2016-2020)，與三度榮獲ONE SHOW中華創意獎–大中華區年度最佳創意代理商。<br/><br/>ADK TAIWAN的每個員工，都將以品牌創意力持續驅動目標，CONNECT消費者與品牌，CONNECT所有線上線下，創造更深、廣、遠的品牌遠景。",
    home_info_watch:"了解 ADK TAIWAN",

    /////////////////////////////////
    //about
    /////////////////////////////////

    about_intro:"ADK TAIWAN，是一家充滿豐沛創意能量的廣告代理商。<br/><br/>我們擅長透過洞察和策略、將強大的熱情和創意能量，巧妙地轉換成精彩而精準的品牌創意，和客戶一起同心打造讓消費者印象深刻與高度影響力的成功案例，結合傳播效益和創意力，為客戶累積品牌力。<br/><br/>ADK TAIWAN的創意實力，履獲國際指標性創意大獎肯定，是台灣唯一榮獲坎城金獅銀獅的創意代理商，更蟬聯3年Campaign Brief Asia Creative Ranking –亞洲最火創意代理商TOP20，與三度榮獲ONE SHOW中華創意獎–大中華區年度最佳創意代理商。<br/><br/>ADK TAIWAN的每個員工，都將以品牌創意力持續驅動目標，CONNECT消費者與品牌，CONNECT所有線上線下，創造更深、廣、遠的品牌遠景。",
    about_title_1:"驅動科技 < 以人為本",
    about_des_1:"我們相信，再創新的科技，都無法自己發聲，所以我們努力運用創造力，讓每個科技能發出驅動世界的聲音。",
    about_title_2:"產生連結 < 產出內容",
    about_des_2:"我們想做的，不只是單純產生連結，而是要以打動人心的廣告內容為目標，讓人與人之間建立關係，推動人們向前邁進",
    about_title_3:"推動加速 < 創造文化",
    about_des_3:"我們始終相信創造文化並使其壯大，才是真正的成長即使必須耗費更多的時間與力氣去完成，但這是唯一能推動並改變世界的作法。",
    about_des_end:"我們採用的是一種「能讓消費者產生行動」的模式，我們稱之為「激活消費者」的全方位溝通模式，透過縝密完整的思考，鼓勵消費者與品牌產生互動，並增加消費者與品牌的接觸點，讓消費者透過體驗，讓品牌自然進入生活。這是一種既能促動消費者，又能幫助客戶提升業績的有效行銷模式。<br/><br/>ADK的傳播服務並不僅限於傳遞訊息。<br/>我們已經超越了“品牌在消費者心中如何定位”這一層面，而是以考慮“如何促動消費者”，也就是說以“如何激活消費者”為目標在持續進化。",
    about_des_end_title:"我們的業務領域",
    /////////////////////////////////
    //Capabliltieses
    /////////////////////////////////
    capabliltieses_title_1:"品牌價值主張",
    capabliltieses_des_1:"<strong>創新品牌體驗</strong><br/>運用來自真實的洞察，透過新穎的創意將品牌目的轉換成消費者中心的體驗溝通模式，創造一個完整的品牌體驗旅程。<br/><br/><strong>創意力X創造文化</strong><br/>我們擁有指標性的創意團隊，並與激發我們靈感的客戶，透過創新思維一起為品牌創造”不可忽略”的故事，將內容轉化為文化，使品牌價值深植於消費者的心中。    ",
    capabliltieses_title_2:"全面傳播溝通",
    capabliltieses_des_2:"<strong>數據驅動策略</strong><br/>以人性真實為依據，透過精準分析，實際掌握痛點，轉換成「激活消費者」立即行動的重點。<br/><br/><strong>平台創新運用X優化整合性解決方案</strong><br/>跳脫媒體型態框架，優化品牌與消費者的所有接觸點，並創造具影響力的內容來實現品牌業績目標。",

    /////////////////////////////////
    //Contact
    /////////////////////////////////
   
    contact_email:'電子信箱',
    contact_email_content:'service@adktaiwan.com',
    contact_tel:'電話',
    contact_tel_content:'886-2-87128555',
    contact_address:'地址',
    contact_address_content:'台北市松山區南京東路三段287號13樓',
    contact_map:'地圖',
    contact_mailto:'聯絡 ADK TAIWAN',


    ///////////////////////////////
    //work
    ///////////////////////////////
    work_gategory:'產品類別',
}

export default lang