<template>
  <div class="_Home">

    <div class="_banner_kv _home_banner">
        <div class="_banner_kv__bg">
          <div class="_banner_kv__bgZoom">
          <div class="_banner_kv__slide">
            <div v-for="(item, index ) in bannerData" :key="index"  class="_banner_kv__img _home_banner__img">
              <img v-if="item.photo && index < 1" class="_banner_kv__bgImg" :src="item.photo">
              <div v-if="item.video && index < 1" class="_banner_kv__iframe">
                <youtube :video-id="item.video.video_id" ref="youtube" :player-vars="{autoplay: 0,controls:0,loop:1,playlist:item.video.video_id,rel:0,volumn:0}"  @ready="videoReady"></youtube>
              </div>
            </div>
          </div>
          </div>
        </div>
        
        <!-- <span class="_banner_kv__img _home_banner__img"></span> -->
        <div class="_banner_kv__container _container">
          <div class="_banner_kv__info  _banner_kv__info--center" :class="[ $store.state.loading ? '' : 'active']">
            <h1 v-if="bannerData.length>0" class="_banner_kv__headLine _banner_kv__headLine--large" data-in-effect="fadeIn" data-in-shuffle="true">{{ bannerData[0].name }}</h1>
          </div>
          <button v-scroll-to="'#main'" class="_banner_kv__scrollDown _btn_hollow _btn_hollow--red _font_btn _btn_hollow--radius font-weight-bold">Scroll Down</button>
        </div>
    </div>

    <div id="main" class="_main">
      
      <div class="_home_bgn">
        <div class="_home_bgn__red">
          <svg viewBox="0 0 647 718" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1033.000000, -1340.000000)" fill="#CA0B21">
                      <path d="M2094.98235,719.9647 L3125,2780 L3125,2780 L963,2780 L1993.01765,719.9647 C2007.09604,691.807925 2041.3344,680.395138 2069.49117,694.473525 C2080.52224,699.989056 2089.46682,708.933638 2094.98235,719.9647 Z" id="Triangle" transform="translate(2044.000000, 1699.000000) rotate(270.000000) translate(-2044.000000, -1699.000000) "></path>
                  </g>
              </g>
          </svg>
        </div>
        <div class="_home_bgn__gray">
          <svg  viewBox="0 0 402 196" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g fill="#F3F3F3">
                      <g transform="translate(0.000000, -375.000000)">
                          <polygon id="Rectangle-Copy-14" transform="translate(201.000000, 472.996441) scale(-1, -1) translate(-201.000000, -472.996441) " points="402 375 402 570.992883 0 570.992883"></polygon>
                      </g>
                  </g>
              </g>
          </svg>
        </div>
      </div>

      <div  class="_home_info">
        <div class="_container">
          <div class="_home_info__box">
            <h2 v-html="$t('home_info_title')" class=" _info_box__title _info_box__title--red">ADK TAIWAN<br/>是一家充滿豐沛創意能量的廣告代理商</h2>
            <p v-html="$t('home_info_des')" class="_info_box__des">
              我們擅長透過洞察和策略、將強大的熱情和創意能量，巧妙地轉換成精彩而精準的品牌創意，和客戶一起同心打造讓消費者印象深刻與高度影響力的成功案例，結合傳播效益和創意力，為客戶累積品牌力。<br/>
ADK TAIWAN的創意實力，履獲國際指標性創意大獎肯定，是台灣唯一榮獲坎城金獅銀獅的創意代理商，更蟬聯3年Campaign Brief Asia Creative Ranking –亞洲最火創意代理商TOP20，與三度榮獲ONE SHOW中華創意獎–大中華區年度最佳創意代理商。<br/>
ADK TAIWAN的每個員工，都將以品牌創意力持續驅動目標，CONNECT消費者與品牌，CONNECT所有線上線下，創造更深、廣、遠的品牌遠景。
            </p>
            <router-link to="/about" class="_info_box__btn _btn_solid _btn_solid--shadow _btn_solid--white _btn_solid__hover _btn_solid__hover--bar"><span>{{$t('home_info_watch')}}</span></router-link>
          </div>
        </div>
      </div>

       <div class="_home_works">
        
        <!-- <div class="_home_works__bgn">
          <div class="_home_works__bgnGray _home_works__bgnGray--1">
              <svg viewBox="0 0 647 718" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-1033.000000, -1340.000000)" fill="#232323">
                        <path d="M2094.98235,719.9647 L3125,2780 L3125,2780 L963,2780 L1993.01765,719.9647 C2007.09604,691.807925 2041.3344,680.395138 2069.49117,694.473525 C2080.52224,699.989056 2089.46682,708.933638 2094.98235,719.9647 Z" id="Triangle" transform="translate(2044.000000, 1699.000000) rotate(270.000000) translate(-2044.000000, -1699.000000) "></path>
                    </g>
                </g>
            </svg>
          </div>
          <div class="_home_works__bgnGray _home_works__bgnGray-2">
            <svg viewBox="0 0 1680 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="HOME" transform="translate(0.000000, -2164.000000)" fill="#232323">
                        <polygon transform="translate(1039.000000, 2681.000000) scale(-1, -1) translate(-1039.000000, -2681.000000) " points="2099 2164 2099 3198 -21 3198"></polygon>
                    </g>
                </g>
            </svg>
          </div>
        </div> -->
        
        <div class="_home_works__container _container ">
          <div class="_home_works__list">
            <div v-for="(item, index ) in works" :key="index"  class="_home_works__item _card">
              <div class="_home_works__itemBox _animation_scroll__stop--3d">
                <router-link :to="'/work/'+item.work.slug">
                  <div class="_home_works__card _card_cover--gray _card_cover--grayHover">
                    <img class="_home_works__cardImg _card_cover__img" v-lazy="item.work.list_photo">
                  </div>
                </router-link>
                <router-link :to="'/work/'+item.work.slug" class="_home_works__name _card_des">
                  <h3>{{item.work.sub_title}}<span class="_card_des__span">{{item.work.name}}</span></h3>
                  <div v-if="item.work.labels != null" class="_card_lables">
                      <span v-for="(lebal,index) in getWorkLebal(item.work.labels)" :key="index">{{lebal}}</span>
                  </div>
                </router-link>
                 <div  v-if="item.work.prize" class="_card_icons">
                  <div v-for="(award, index ) in item.work.prize" :key="index" class="_card_icons__icon">
                      <img :src="award.photo"/>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>
        <div class="_home_works__more ">
            <router-link :to="'/work'">
              <div class="_home_works__moreBtn">
                More Works
              </div>
            </router-link>
        </div>

      </div>

      <div class="_home_news" v-if="recommend.length">
        <swiper :options="newsOptions" class="_home_slide ">
            <swiper-slide v-for="(item,index) in recommend" :key="index" class="_home_news__item">
              <a :href="item.link" :target="item.link_type" class="_home_news__link">
                <!-- <span class="_home_news__data">{{item.date}}</span> -->
                <h3 class="_home_news__title">{{item.name}}</h3>
                <span class="_home_news__point">…</span>
              </a>
            </swiper-slide>
        </swiper>
      
    </div>
      
    </div>

   

  </div>
</template>

<script>
import { gsap,TimelineMax,TweenMax  } from 'gsap'
import $ from 'jquery'
import commonMixin from '../utils/commonMixin'

export default {
  name: 'Home',
  data:function(){
      return{
        bannerData:[],
        imageData:[],
        works:[],
        recommend:[],
        worksOption: {
          slidesPerView: 1.3,
          // init: false,
          pagination: {
            //el: '.swiper-pagination',
            clickable: true
          },
          breakpoints: {
            800: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 15
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          }
        },
        newsOptions:{
          slidesPerView: 3,
          loop: true,
          pagination: {
            clickable: false
          },
          autoplay: {
            delay:5000,
            disableOnInteraction: false
          },
          breakpoints: {
            1000: {
              
              slidesPerView: 2,
            },
            800: {
              
              slidesPerView: 1,
              spaceBetween: 0
            }
          }
        },
        bgSenceRed:{},
        bgSenceGray:{},
        workBgnSence:{},
        home_works:{}
      }
  },
  mounted:function(){
    this.getAPIdatas();
    //console.log("bannerData:::",this.bannerData)
    //console.log("$cookies::::",window.$cookies.get('lang_code'))
    //this.initScrollMagic();
    // if(!this.$store.state.bannerData){
    //   this.loadBanner('home',false)
    // }
  },
  methods:{
    
    getAPIdatas() {
      var _this=this
      this.axios({
          method: "get",
          url: "/api/banner?category_slug=home"
        })
        .then(response => {
          for(let i in response.data.items){
            this.imageData.push(response.data.items[i].photo)
          }
          this.bannerData=response.data.items
          //console.log('bannerData',this.bannerData)
          //_this.$store.commit('setBannerData',data)
          this.preloadImages(this.imageData,function(){
            _this.$store.commit('setLoading',false);
            _this.tweenText($('._banner_kv__headLine'),true)
            _this.initBanner();
            //console.log("allImageLoaded!!")
          })
          //console.log("bannerData::",this.bannerData)
        })
        .catch(function(err) {
          console.log(err);
        });
      

      this.axios({
        method: "get",
        url: "/api/work/recommend"
      })
      .then(response => {
        console.log('works:',response);
        var _this=this
        this.works = response.data.items;
        
        setTimeout(function(){
          _this.initScrollMagic()
        },300)
        //console.log('player',_this.player)
        
      })
      .catch(function(err) {
        console.log(err);
      });

      this.axios({
        method: "get",
        url: "/api/recommend"
      })
      .then(response => {
        //console.log("recommend",response);
        this.recommend = response.data.items;
      })
      .catch(function(err) {
        console.log(err);
      });
    },
    initScrollMagic:function(){
      
      console.log('this.$scrollmagic:::',this.$scrollmagic)
      var _this=this;
        this.bgSenceRed = this.$scrollmagic.scene({
          triggerElement:'._home_bgn',
          duration:'300%',
          triggerHook: 1
        })
        .setTween("._home_bgn__red", {y: '70%'})
        this.$scrollmagic.addScene(this.bgSenceRed)

        this.bgSenceGray = this.$scrollmagic.scene({
          triggerElement:'._home_bgn',
          duration:'200%',
          triggerHook: 1
        })
        .setTween("._home_bgn__gray", {y: "0px"})
        this.$scrollmagic.addScene(this.bgSenceGray);
        
        $('._card_cover__img').each(function(index){
          //alert(index);
          _this['worksImg'+index] = _this.$scrollmagic.scene({
              triggerElement:this,
              triggerHook:1,
              duration:'200%'
          })
          .setTween($(this), {css:{
            'transform':'scale(1,1)'
          }})
          _this.$scrollmagic.addScene(_this['worksImg'+index]);
        })
  
        this.workBgnSence = this.$scrollmagic.scene({
          triggerElement:'._home_works',
          duration:'600%',
          triggerHook: 1
          })
          .setTween("._home_works", {css:{
            'background-position-y':'50%'
          }})
          this.$scrollmagic.addScene(_this.workBgnSence)

        //console.log($,_this);

        // this.home_works = this.$scrollmagic.scene({
        //     triggerElement:'._home_works',
        //     duration:$('._home_works').height()+$(window).height(),
        //     triggerHook: 0
        //     //offset:-50
        // })
        // .setClassToggle('._header_logo__txt','white')
        // this.$scrollmagic.addScene(_this.home_works)
        
      }
    
      //let worksItem = getElementsByClassName("test");
      
  },
  destroyed () {
    this.destoryBanner()
    
    // this.bgSenceGray.destory(true)
    // this.workBgnSence.destory(true)
    // this.home_works.destory(true)
    // this.bgSenceRed.destory(true)
  }
  
}
</script>
