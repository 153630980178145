<template>
  <div class="_notfound">
    404
  </div>
</template>

<script>
import commonMixin from '../utils/commonMixin'
import $ from 'jquery'
export default {
  name: 'NotFound',
  data:function(){
      return{
        // bannerData:[],
        // imageData:[],
      }
  },
  mounted:function(){
    // this.getAPIdatas();
    // this.initScrollMagic();
  },
  methods:{

  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setLoading',true)
    next()
  }
}
</script>
